import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {LetterBannerUpdate, NewsAdd, NewsChangeImage, NewsUpdate} from '../../../services/main'
import {toast} from 'react-toastify'
import {
  LetterBannerUpdateReq,
 
} from '../../../services/Request'
interface IProps {
  t: any
  item: any
  onAddSuccess: any
}
const LetterEdit: FC<IProps> = ({t, item, onAddSuccess}) => {
  const [title, setTitle] = useState(isNullOrEmpty(item) ? '' : item.title)
  const [ug, setUg] = useState(item.ug)

  const [desc, setDesc] = useState(isNullOrEmpty(item) ? '' : item.description)
  const [imageBase64, setImageBase64] = React.useState<any>('')
  const [lang, setLang] = useState(isNullOrEmpty(item) ? 'mn' : item.lang)
  const [category, setCategory] = useState(isNullOrEmpty(item) ? '' : item.category)
  const [count, setCount] = useState(0)
  const [loading, setLaoding] = useState(false)
  const [loadingImageData, setLaodingImageData] = useState(false)
  const [validate, setValidate] = useState<any>([])
  const [active, setActive] = useState(item.tolov)

  const onFailed = (err: string) => {
    setLaoding(false)
    return toast.error(err)
  }

  const update = () => {
    setLaoding(true)
    var req = LetterBannerUpdateReq
    req.order.id = item.id
    req.order.tolov = active
    req.order.ug = ug
    LetterBannerUpdate(req, onUpdateSuccess, onFailed, 'PUT')
  }

  const onUpdateSuccess = () => {
    setLaoding(false)
    onAddSuccess()
    return toast(t('common:successUpdate'))
    
  }

  return (
    <div>
      <div className='mx-5 mx-xl-10 pt-10 pb-10 w-500px'>
        <span style={{visibility: 'hidden'}}>{count}</span>
        <div className='text-center mb-13'>
          <h1 className='mb-3'>{t('common:LETTERBANNER.edit')}</h1>
        </div>

        <div className='row'>
          <div>
            <img width={120} height={120} src={`../letters/${item.pid}.jpg`} alt='' />
            <span style={{marginLeft: '16px', fontWeight: '600', fontSize: '13px'}}>{item.ug}</span>
          </div>
        </div>

        <div className='separator mt-8 d-flex flex-center mb-8'>
          <span className='text-uppercase bg-body fs-7 fw-bold text-muted px-3'>
            Id: {item.id} {t('common:LETTERBANNER.edit')}
          </span>
        </div>
        <div className='mb-8'>
          <input
            className='form-control form-control-solid'
            placeholder={t('common:LETTERBANNER.ug')}
            onChange={(e) => {
              setUg(e.target.value)
            }}
            value={ug}
          ></input>
          {validate['ug'] ? (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{t('common:validInsert')}</div>
            </div>
          ) : null}
        </div>
        <select
          onChange={(e) => setActive(e.target.value)}
          className='form-select form-select-sm form-select-solid'
          id=''
          value={active}
        >
          <option value=''>{t('common:validSelect')}</option>
          <option value='1'>{t('common:LETTERBANNER.active')}</option>
          <option value='0'>{t('common:LETTERBANNER.inactive')}</option>
        </select>

        <div className='pt-10' style={{textAlign: 'right'}}>
          <button className='btn btn-primary align-self-center' onClick={update} disabled={loading}>
            {loading ? t('common:loading') : t('common:edit')}
          </button>
        </div>
      </div>
    </div>
  )
}

export {LetterEdit}
