import {FC, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DataTable from '../../modules/CustomBootsrapTable/CustomBootsrapTable'
import {
  dateFormat,
  encodeString,
} from '../../../services/Utils'
import {
  BaseRequest,
  DTempleteChangeStatusReq,
  QRListReq,
  magicnum,
} from '../../../services/Request'
import {
  CDN_URL,
  DTemapleteChangeStatus,
  QRList,
  deleteQRCode,
  iNVOICEMULTIPLIER,
} from '../../../services/main'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'
import ModalImage from 'react-modal-image'
import {useSearchParams} from 'react-router-dom'
const dashboardBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
]
type Props = {
  type?: string
  uid?: any
}

const Qr: FC<Props> = (props) => {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [item, setItem] = useState(undefined)
  const [PID, setPID] = useState(1)
  const [PTotal, setPTotal] = useState(1)
  const [sizePerPage, setSizePerPage] = useState(10)
  const [showModal, updateShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [name, setName] = useState('')
  const [category, setCategory] = useState('')
  const [sub_category, setSub_category] = useState('')
  const [status, setStatus] = useState<any>('')
  const [qr_type, setQr_type] = useState('')
  const [uid, setUid] = useState('')

  useEffect(() => {
    list()
  }, [PID])

  const list = () => {
    if (!loading) {
      setLoading(true)
    }
    var req = QRListReq
    req.pid = PID
    req.pSize = sizePerPage
    req.filter.qr_type = qr_type
    req.filter.status = status
    req.filter.uid = props.type == 'component' ? props.uid : uid
    QRList(req, onSuccess, onFailed, 'POST')
  }
  const onSuccess = (response: any) => {
    setLoading(false)
    setData(response.data)
    setPTotal(response.pagination.ptotal)
  }
  const onFailed = (error: string) => {
    setLoading(false)
    return toast.error(error)
  }
  const onChangePage = (pid: any) => {
    if (pid >= 1) {
      setPID(pid)
      setLoading(true)
    }
  }
  const defaultRowFormat = (cell: any, row: any) => {
    return <span className='flex justify-center'>{cell}</span>
  }
  const imgRowFormat = (cell: string, row: any) => {
    return (
      <div className='text-center' style={{cursor: 'pointer', objectFit: 'contain'}}>
        <ModalImage
          className='max-h-100px max-w-150px'
          small={CDN_URL + cell}
          large={CDN_URL + cell}
          alt={row.id}
        />
      </div>
    )
  }
  const nameRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex justify-center flex-col'>
        <span>{cell}</span>
        <span>{row.qr_type}</span>
        <Link to={`/user/${row.uid}`} className='cursor-pointer'>
          {row.uid}
        </Link>
      </div>
    )
  }
  const linkRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col gap-2'>
        <a target='_blank' href={'https://qr.grado.mn/v/' + (cell * iNVOICEMULTIPLIER)}>
          {'https://qr.grado.mn/v/' + (cell * iNVOICEMULTIPLIER)}
        </a>
        <a target='_blank' href={row.qr_url}>
          {row.qr_url.length > 50 ? row.qr_url.slice(0, 50) + '...' : row.qr_url}
        </a>
      </div>
    )
  }
  const statusRowFormat = (cell: any, row: any) => {
    return (
      <div className='flex flex-col items-center gap-2 justify-center'>
        <span
          onClick={() => submit(row, 'changeStatus')}
          style={{cursor: 'pointer'}}
          className={`badge ${cell == '0' ? 'badge-light-warning' : 'badge-light-success'}`}
        >
          {t(`common:magicnum.BrandbookLogos.status.${cell}`)}
        </span>
        <span>{dateFormat(row.created_at)}</span>
      </div>
    )
  }
  const actionRowFormat = (cell: string, row: any) => {
    return (
      <div className='justify-center flex' style={{minWidth: '229px'}}>
        <Link to={'/qr/' + row.id}>
          <div className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <KTIcon iconName='switch' className='fs-3' />
          </div>
        </Link>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => {
            setItem(row)
            updateShowModal(true)
          }}
        >
          <KTIcon iconName='pencil' className='fs-3' />
        </div>
        <div
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
          onClick={() => submit(row, 'deleteMain')}
        >
          <KTIcon iconName='trash' className='fs-3' />
        </div>
      </div>
    )
  }
  const submit = (row: any, type: string) => {
    confirmAlert({
      customUI: ({onClose}) => {
        return (
          <div className='custom-confirm-ui'>
            <h1>{row.title}</h1>
            <p>
              {' '}
              {type == 'deleteMain' ? t('common:confirmDelete') : t('common:confirmChangeStatus')}
            </p>
            <div className='buttons'>
              <button className='btn btn-active-light btn-color-muted' onClick={onClose}>
                {t('common:no')}
              </button>
              <button
                className='btn btn-primary align-self-center'
                onClick={() => {
                  type == 'deleteMain' ? deleteFunc(row) : changeStatus(row)
                  onClose()
                }}
              >
                {t('common:yes')}
              </button>
            </div>
          </div>
        )
      },
    })
  }
  const deleteFunc = (row: any) => {
    deleteQRCode(row.id, BaseRequest, onDeleteSuccess, onFailed, 'DELETE')
  }
  const onDeleteSuccess = () => {
    list()
    return toast.success(t('common:successDelete'))
  }
  const changeStatus = (row: any) => {
    var req = DTempleteChangeStatusReq
    req.desgin.id = row.id
    if (row.status == 0) {
      req.desgin.status = JSON.stringify(1)
    }
    if (row.status == 1) {
      req.desgin.status = JSON.stringify(0)
    }

    DTemapleteChangeStatus(req, onChangeStatusSuccess, onFailed, 'PUT')
  }
  const onChangeStatusSuccess = () => {
    list()
    return toast.success(t('common:successUpdate'))
  }
  const clearFilter = () => {
    setName('')
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  const search = () => {
    if (PID > 1) {
      setPID(1)
    } else {
      list()
    }
  }
  var table = {
    sizePerPage: sizePerPage,
    headerKeyColumn: {
      title: 'id',
      dataField: 'PrivNo',
      dataSort: true,
      dataFormat: defaultRowFormat,
      hidden: true,
      filter: {
        type: 'TextFilter',
        placeholder: '...',
      },
    },
    headerOtherColumn: [
      {
        title: 'Preview',
        dataField: 'qr_code',
        dataSort: false,
        dataFormat: imgRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'QR name',
        dataField: 'name',
        dataSort: false,
        dataFormat: nameRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Total views',
        dataField: 'totalview',
        dataSort: false,
        dataFormat: defaultRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: 'Link',
        dataField: 'id',
        dataSort: false,
        dataFormat: linkRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:TEMPLATE.status'),
        dataField: 'status',
        dataSort: false,
        dataFormat: statusRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
      {
        title: t('common:LETTERBANNER.action'),
        dataField: 'id',
        dataSort: false,
        dataFormat: actionRowFormat,
        hidden: false,
        filter: {
          type: 'TextFilter',
          placeholder: '...',
        },
      },
    ],
  }

  return (
    <>
      {/* {showModal ? (
        <CustomModal
          visible={showModal}
          closeModal={() => updateShowModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type='edit' onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}

      {/* {orgModal ? (
        <CustomModal
          visible={orgModal}
          closeModal={() => setOrgModal(false)}
          className='VideoModal'
        >
          <TemplateEdit t={t} item={item} type="org" onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}

      {/* {tagModal ? (
        <CustomModal
          visible={tagModal}
          closeModal={() => setTagModal(false)}
          className='VideoModal'
        >
          <TemplateTag t={t} item={item} onAddSuccess={onAddSuccess} />
        </CustomModal>
      ) : null} */}
      {props.type == 'component' ? null : (
        <>
          <PageTitle breadcrumbs={dashboardBreadCrumbs}>{t('common:QR.qrname')}</PageTitle>

          <div className={`card mb-5`}>
            <div className='card-header border-1' style={{height: '60px'}}>
              <div
                onClick={() => setShowFilter(!showFilter)}
                className=' cursor-pointer filter flex items-center justify-center gap-0'
              >
                <KTIcon iconName='filter' className='fs-3' />
                <span className='text'>{t('common:filter')}</span>
              </div>
              <div className='card-toolbar'>
                <a onClick={() => search()} className='btn btn-sm btn-light-primary'>
                  {t('common:search')}
                </a>
              </div>
            </div>
            {showFilter ? (
              <div className='card-body'>
                <div className='row flex items-center filter-wrap'>
                  <div className='col'>
                    <input
                      className='form-control form-control-sm form-control-solid'
                      id='name'
                      type='text'
                      onChange={(e) => setName(e.target.value)}
                      placeholder='Name'
                      value={name}
                    />
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setCategory(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='category'
                      value={category}
                    >
                      <option value=''>{t('common:ELEMENT.select_cat')}</option>
                      {magicnum.Category.map((pitem) => {
                        return (
                          <option value={pitem}>{t(`common:magicnum.Category.${pitem}`)}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setSub_category(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      name='sub_category'
                      value={sub_category}
                    >
                      <option value=''>{t('common:ELEMENT.select_sub_cat')}</option>
                      {magicnum.SubCategory.filter(
                        (sub: any) => sub.parent.toString() === category
                      ).map((pitem) => {
                        return (
                          <option value={pitem.id}>
                            {t(`common:magicnum.SubCategory.${category}-${pitem.id}`)}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col'>
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value)
                      }}
                      className='form-select form-select-sm form-select-solid'
                      value={status}
                    >
                      <option value=''>{t('common:employee.selectStatus')}</option>
                      <option value={magicnum.Status.InActive}>
                        {t('common:magicnum.Status.InActive')}
                      </option>
                      <option value={magicnum.Status.Active}>
                        {t('common:magicnum.Status.Active')}
                      </option>
                    </select>
                  </div>
                  <div className='col'>
                    <a
                      onClick={() => clearFilter()}
                      className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    >
                      {t('common:USERS.clear')}
                    </a>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </>
      )}

      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{t('common:QR.list')}</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              {t('common:QR.total', {count: PTotal})}
            </span>
          </h3>
        </div>
        <div className='card-body py-3'>
          <DataTable
            data={data}
            table={table}
            PTotal={PTotal}
            pageChange={onChangePage}
            PID={PID}
            loading={loading}
          />
        </div>
      </div>
    </>
  )
}

export {Qr}
