export const BaseRequest = {
  lang: localStorage.getItem('Lang') === null ? 'mn' : localStorage.getItem('Lang'),
}

export const ListBase = {
  ...BaseRequest,
  pid: 1,
  pSize: 25,
}

export const magicnum = {
  bagts: [
    {
      skey: 'grado',
      sub1: 35000,
      sub2: 55000,
      sub3: 99000,
      all: 140000,
    },
    {skey: 'tool', sub1: 35000, sub2: 55000},
    {skey: 'flipbook', sub1: 35000, sub2: 55000},
    {skey: 'qr', sub1: 35000, sub2: 55000},
    {skey: 'all', super: 140000},
  ],
  Status: {
    Active: 1,
    InActive: 0,
  },
  DOrder: {
    transfer: 1,
    notransfer: 0,
  },
  StatusTemplate: {
    InActive: 0,
    Active: 1,
    Cancelled: 2,
    Rejected: 3,
    Deleted: 9,
  },
  FeedBack: [1, 2, 3, 4, 5],
  Mounth: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  temdegleltOdor: [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
    27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
    75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
  ],
  letters: [
    {
      id: "1",
      category: ":1:",
      text: "welcome",
      status: false,
      url: "COMTc1OA",
      tUrl: "COMTc1OA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "2",
      category: ":2:",
      text: "happy",
      status: true,
      url: "COMTczNw",
      tUrl: "COMTczNw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "3",
      category: ":2:",
      text: "баярын мэнд",
      status: false,
      url: "COMTcyMA",
      tUrl: "COMTcyMA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "4",
      category: ":3:",
      text: "ананд сэвлэг үргээх ёслол",
      status: false,
      url: "COMTY5NA",
      tUrl: "COMTY5NA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "5",
      category: ":3:",
      text: "сэвлэг үргээх",
      status: true,
      url: "COMTgzOQ",
      tUrl: "COMTgzOQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "6",
      category: ":3:",
      text: "сэвлэг үргээх",
      status: false,
      url: "COMTg0MA",
      tUrl: "COMTg0MA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "7",
      category: ":3:",
      text: "сэвлэг үргээх",
      status: false,
      url: "COMTg0MQ",
      tUrl: "COMTg0MQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "8",
      category: ":3:",
      text: "сэвлэг үргээх",
      status: true,
      url: "COMTg0Mg",
      tUrl: "COMTg0Mg",
      type: "letter",
      multiColor: true,
    },

    {
      id: "9",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk2NA",
      tUrl: "COMTk2NA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "10",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk2NQ",
      tUrl: "COMTk2NQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "11",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk2Nw",
      tUrl: "COMTk2Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "12",
      category: ":5:",
      text: "алтан намар",
      status: true,
      url: "COMTk3MQ",
      tUrl: "COMTk3MQ",
      type: "letter",
      multiColor: false,
    },
    {
      id: "13",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk3Mg",
      tUrl: "COMTk3Mg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "14",
      category: ":5:",
      text: "алтан намар",
      status: true,
      url: "COMTk3Mw",
      tUrl: "COMTk3Mw",
      type: "letter",
      multiColor: false,
    },
    {
      id: "15",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk4NQ",
      tUrl: "COMTk4NQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "16",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTk4Nw",
      tUrl: "COMTk4Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "17",
      category: ":5:",
      text: "алтан намар",
      status: true,
      url: "COMTk4OA",
      tUrl: "COMTk4OA",
      type: "letter",
      multiColor: false,
    },
    {
      id: "18",
      category: ":5:",
      text: "алтан намар",
      status: true,
      url: "COMTk2Ng",
      tUrl: "COMTk2Ng",
      type: "letter",
      multiColor: false,
    },
    {
      id: "19",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTg5Mw",
      tUrl: "COMTg5Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "20",
      category: ":5:",
      text: "алтан намар",
      status: false,
      url: "COMTcyOQ",
      tUrl: "COMTcyOQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "21",
      category: ":1:2:6:7:",
      text: "намар",
      status: true,
      url: "COMTcyNA",
      tUrl: "COMTcyNA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "22",
      category: ":5:",
      text: "алтан намар",
      status: true,
      url: "COMTk3MQ2",
      tUrl: "COMTk3MQ",
      type: "letter",
      multiColor: false,
    },
    {
      id: "23",
      category: ":6:",
      text: "эрдийн баярын мэнд",
      status: false,
      url: "COMTM3OA",
      tUrl: "COMTM3OA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "24",
      category: ":6:",
      text: "эрдийн баярын мэнд",
      status: false,
      url: "COMTM4MA",
      tUrl: "COMTM4MA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "25",
      category: ":6:",
      text: "ангидаа давтай морил",
      status: false,
      url: "COMTcwMg",
      tUrl: "COMTcwMg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "26",
      category: ":6:",
      text: "ангидаа тавтай морилно уу",
      status: false,
      url: "COMTcwMw",
      tUrl: "COMTcwMw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "27",
      category: ":6:",
      text: "ангидаа тавтай морилно уу",
      status: false,
      url: "COMTcxMA",
      tUrl: "COMTcxMA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "28",
      category: ":6:",
      text: "эрдийн өргөө",
      status: true,
      url: "COMTcxMQ",
      tUrl: "COMTcxMQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "29",
      category: ":6:",
      text: "бидний сургууль",
      status: true,
      url: "COMTcxMw",
      tUrl: "COMTcxMw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "30",
      category: ":6:",
      text: "ангидаа тавтай морилно уу ",
      status: true,
      url: "COMTcxNQ",
      tUrl: "COMTcxNQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "31",
      category: ":6:",
      text: "эрдэм ном",
      status: true,
      url: "COMTcxNg",
      tUrl: "COMTcxNg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "32",
      category: ":6:",
      text: "9 сарын 1",
      status: false,
      url: "COMTcyNg",
      tUrl: "COMTcyNg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "33",
      category: ":6:",
      text: "хичээлийн шинэ жил",
      status: false,
      url: "COMTczMQ",
      tUrl: "COMTczMQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "34",
      category: ":6:",
      text: "2023 9сарын1 2024",
      status: false,
      url: "COMTc0Nw",
      tUrl: "COMTc0Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "35",
      category: ":6:",
      text: "хичээлийн шинэ жил",
      status: true,
      url: "COMTc0OA",
      tUrl: "COMTc0OA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "36",
      category: ":6:",
      text: "цэцэрлэгтээ тавтай морил",
      status: false,
      url: "COMTc1Mg",
      tUrl: "COMTc1Mg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "37",
      category: ":6:",
      text: "back to school",
      status: false,
      url: "COMTc1Nw",
      tUrl: "COMTc1Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "38",
      category: ":6:",
      text: "9 сарын 1",
      status: false,
      url: "COMTc2Nw",
      tUrl: "COMTc2Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "39",
      category: ":6:",
      text: "9 сарын 1",
      status: false,
      url: "COMTczNA",
      tUrl: "COMTczNA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "40",
      category: ":6:",
      text: "хичээлийн шинэ жил",
      status: false,
      url: "COMTcwNg",
      tUrl: "COMTcwNg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "41",
      category: ":5:6:",
      text: "цэцэг",
      status: true,
      url: "COMTcyNQ",
      tUrl: "COMTcyNQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "42",
      category: ":6:",
      text: "123-р сургууль",
      status: true,
      url: "COMTcyOA",
      tUrl: "COMTcyOA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "43",
      category: ":6:",
      text: "шинэхэн цэцэрлэг",
      status: false,
      url: "COMTczMg",
      tUrl: "COMTczMg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "44",
      category: ":6:",
      text: "бидний сургууль",
      status: true,
      url: "COMTczMw",
      tUrl: "COMTczMw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "45",
      category: ":6:",
      text: "бидний анги",
      status: false,
      url: "COMTczNQ",
      tUrl: "COMTczNQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "46",
      category: ":6:",
      text: "бидний анги",
      status: false,
      url: "COMTc1Mw",
      tUrl: "COMTc1Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "47",
      category: ":6:",
      text: "3г анги",
      status: false,
      url: "COMTc1NQ",
      tUrl: "COMTc1NQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "48",
      category: ":6:",
      text: "бүжин бүлэг",
      status: false,
      url: "COMTc2Mg",
      tUrl: "COMTc2Mg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "49",
      category: ":6:",
      text: "дэгдээхэй бүлэг",
      status: false,
      url: "COMTc2Mw",
      tUrl: "COMTc2Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "50",
      category: ":6:",
      text: "3-2 анги",
      status: false,
      url: "COMTc2OA",
      tUrl: "COMTc2OA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "51",
      category: ":7:6:",
      text: "абв",
      status: true,
      url: "COMTcwNA",
      tUrl: "COMTcwNA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "52",
      category: ":7:6:",
      text: "абв",
      status: false,
      url: "COMTcwNQ",
      tUrl: "COMTcwNQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "53",
      category: ":7:",
      text: "абвг",
      status: false,
      url: "COMTcxMg",
      tUrl: "COMTcxMg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "54",
      category: ":7:",
      text: "абв",
      status: false,
      url: "COMTcxOA",
      tUrl: "COMTcxOA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "55",
      category: ":7:",
      text: "абвгдеёж",
      status: true,
      url: "COMTcxOQ",
      tUrl: "COMTcxOQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "56",
      category: ":7:",
      text: "тарвас",
      status: false,
      url: "COMTcyMQ",
      tUrl: "COMTcyMQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "57",
      category: ":5:7:",
      text: "солонго үүл",
      status: true,
      url: "COMTcyMg",
      tUrl: "COMTcyMg",
      type: "letter",
      multiColor: false,
    },
    {
      id: "58",
      category: ":7:",
      text: "а б",
      status: true,
      url: "COMTcyMw",
      tUrl: "COMTcyMw",
      type: "letter",
      multiColor: true,
    },

    {
      id: "59",
      category: ":7:",
      text: "а",
      status: false,
      url: "COMTM1MA",
      tUrl: "COMTM1MA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "60",
      category: ":7:",
      text: "б",
      status: false,
      url: "COMTM1MQ",
      tUrl: "COMTM1MQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "61",
      category: ":7:",
      text: "г",
      status: false,
      url: "COMTM1Mw",
      tUrl: "COMTM1Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "62",
      category: ":7:",
      text: "д",
      status: false,
      url: "COMTM1NA",
      tUrl: "COMTM1NA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "63",
      category: ":7:",
      text: "а",
      status: true,
      url: "COMTM4MQ",
      tUrl: "COMTM4MQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "64",
      category: ":7:",
      text: "б",
      status: false,
      url: "COMTM4Mg",
      tUrl: "COMTM4Mg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "65",
      category: ":7:",
      text: "в",
      status: false,
      url: "COMTM4Mw",
      tUrl: "COMTM4Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "66",
      category: ":7:",
      text: "г",
      status: false,
      url: "COMTM4NA",
      tUrl: "COMTM4NA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "67",
      category: ":7:",
      text: "д",
      status: false,
      url: "COMTM4NQ",
      tUrl: "COMTM4NQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "68",
      category: ":7:",
      text: "е",
      status: false,
      url: "COMTM4Ng",
      tUrl: "COMTM4Ng",
      type: "letter",
      multiColor: true,
    },
    {
      id: "99",
      category: ":7:",
      text: "ё",
      status: true,
      url: "COMTM4Nw",
      tUrl: "COMTM4Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "70",
      category: ":7:",
      text: "ж",
      status: true,
      url: "COMTM4OA",
      tUrl: "COMTM4OA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "71",
      category: ":7:",
      text: "з",
      status: true,
      url: "COMTM4OQ",
      tUrl: "COMTM4OQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "72",
      category: ":7:",
      text: "и",
      status: false,
      url: "COMTM5MA",
      tUrl: "COMTM5MA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "73",
      category: ":7:",
      text: "а",
      status: false,
      url: "COMTcwNw",
      tUrl: "COMTcwNw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "74",
      category: ":7:",
      text: "в",
      status: false,
      url: "COMTM1Mg",
      tUrl: "COMTM1Mg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "75",
      category: ":9:",
      text: "happy boo day",
      status: false,
      url: "COMjA2Ng",
      tUrl: "COMjA2Ng",
      type: "letter",
      multiColor: true,
    },
    {
      id: "76",
      category: ":9:",
      text: "happy halloween",
      status: false,
      url: "COMjA2Mw",
      tUrl: "COMjA2Mw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "77",
      category: ":9:",
      text: "happy boo day",
      status: false,
      url: "COMjA2NQ",
      tUrl: "COMjA2NQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "78",
      category: ":9:",
      text: "happy halloween",
      status: false,
      url: "COMjA2Nw",
      tUrl: "COMjA2Nw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "79",
      category: ":9:",
      text: "happy halloween",
      status: false,
      url: "COMjA2MA",
      tUrl: "COMjA2MA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "80",
      category: ":1:2:6:",
      text: "багш нарын баярын мэнд",
      status: false,
      url: "COMTkyMQ",
      tUrl: "COMTkyMQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "81",
      category: ":1:2:6:",
      text: "багш нарын баярын мэнд",
      status: true,
      url: "COMTkyNA",
      tUrl: "COMTkyNA",
      type: "letter",
      multiColor: false,
    },
    {
      id: "82",
      category: ":1:2:6:",
      text: "багш нарын баярын мэнд",
      status: false,
      url: "COMTkyMg",
      tUrl: "COMTkyMg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "83",
      category: ":1:2:6:",
      text: "багш нарын баярын мэнд",
      status: false,
      url: "COMTkyNQ",
      tUrl: "COMTkyNQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "84",
      category: ":1:2:6:",
      text: "багш нарын баяр",
      status: false,
      url: "COMTkyNg",
      tUrl: "COMTkyNg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "85",
      category: ":1:2:6:",
      text: "багш нарын баяр",
      status: false,
      url: "COMTkyNw",
      tUrl: "COMTkyNw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "86",
      category: ":1:2:6:",
      text: "багш нарын баяр",
      status: true,
      url: "COMTkyOA",
      tUrl: "COMTkyOA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "87",
      category: ":1:2:6:",
      text: "багш нарын баярын мэнд",
      status: false,
      url: "COMTkyOQ",
      tUrl: "COMTkyOQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "88",
      category: ":1:2:6:",
      text: "багш нарын баяр",
      status: true,
      url: "COMTkzMA",
      tUrl: "COMTkzMA",
      type: "letter",
      multiColor: true,
    },
    {
      id: "89",
      category: ":10:",
      text: "багш нарын баяр",
      status: false,
      url: "COMTkzMQ",
      tUrl: "COMTkzMQ",
      type: "letter",
      multiColor: true,
    },
    {
      id: "100",
      category: ":1:6:",
      text: "багш",
      status: true,
      url: "COMTkzMw",
      tUrl: "COMTkzMw",
      type: "letter",
      multiColor: true,
    },
    {
      id: "101",
      category: ":1:6:",
      text: "багш",
      status: true,
      url: "COMTkzMg",
      tUrl: "COMTkzMg",
      type: "letter",
      multiColor: true,
    },
    {
      id: "102",
      category: ":10:",
      text: "чадварын тайлан 2023",
      status: false,
      url: "COMTM3OQ",
      tUrl: "COMTM3OQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "103",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4OA",
      tUrl: "COMjE4OA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "104",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4NA",
      tUrl: "COMjE4NA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "105",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4NQ",
      tUrl: "COMjE4NQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "106",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4OQ",
      tUrl: "COMjE4OQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "107",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4MQ",
      tUrl: "COMjE4MQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "108",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4Mg",
      tUrl: "COMjE4Mg",
      type: "letter",
      multiColor: true,
    },

    {
      id: "109",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE4Mw",
      tUrl: "COMjE4Mw",
      type: "letter",
      multiColor: true,
    },

    {
      id: "110",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjE5Nw",
      tUrl: "COMjE5Nw",
      type: "letter",
      multiColor: true,
    },

    {
      id: "111",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjIwMg",
      tUrl: "COMjIwMg",
      type: "letter",
      multiColor: true,
    },

    {
      id: "112",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjIwMQ",
      tUrl: "COMjIwMQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "113",
      category: ":12:",
      text: "шинэ жил 2024",
      status: false,
      url: "COMjIwNQ",
      tUrl: "COMjIwNQ",
      type: "letter",
      multiColor: true,
    },

    {
      id: "114",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjMwMA",
      tUrl: "COMjMwMA",
      type: "letter",
      multiColor: true,
    },

    {
      id: "115",
      category: ":12:",
      text: "шинэ жил 2024",
      status: true,
      url: "COMjMwM8",
      tUrl: "COMjMwM8",
      type: "letter",
      multiColor: true,
    },

    {
      id: "116",
      category: ":13:",
      text: "",
      status: true,
      type: "balloon",
      url: "balloon/blue",
      image: "balloon-blue",
      tUrl: "",
      multiColor: true,
    },
    {
      id: "117",
      category: ":13:",
      text: "",
      status: true,
      type: "balloon",
      url: "balloon/gold",
      image: "balloon-gold",
      tUrl: "",
      multiColor: true,
    },
    {
      id: "118",
      category: ":13:",
      text: "",
      status: true,
      type: "balloon",
      url: "balloon/pink",
      image: "balloon-pink",
      tUrl: "",
      multiColor: true,
    },
    {
      id: "119",
      category: ":13:",
      text: "",
      status: true,
      type: "balloon",
      url: "balloon/silver",
      image: "balloon-silver",
      tUrl: "",
      multiColor: true,
    },
    {
      id: "120",
      category: ":2:",
      text: "happy",
      status: true,
      url: "XX0001",
      tUrl: "XX0001",
      type: "letter",
      multiColor: true,
    },

    {
      id: "121",
      category: ":2:",
      text: "happy",
      status: true,
      url: "XX0002",
      tUrl: "XX0002",
      type: "letter",
      multiColor: true,
    },

    {
      id: "122",
      category: ":2:",
      text: "happy",
      status: true,
      url: "XX0003",
      tUrl: "XX0003",
      type: "letter",
      multiColor: true,
    },

    {
      id: "123",
      category: ":2:",
      text: "happy",
      status: true,
      url: "XX0004",
      tUrl: "XX0004",
      type: "letter",
      multiColor: true,
    },

    {
      id: "124",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0005",
      tUrl: "XX0005",
      type: "letter",
      multiColor: true,
    },

    {
      id: "125",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0006",
      tUrl: "XX0006",
      type: "letter",
      multiColor: true,
    },
    {
      id: "126",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0007",
      tUrl: "XX0007",
      type: "letter",
      multiColor: true,
    },

    {
      id: "127",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0008",
      tUrl: "XX0008",
      type: "letter",
      multiColor: true,
    },

    {
      id: "128",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0009",
      tUrl: "XX0009",
      type: "letter",
      multiColor: true,
    },

    {
      id: "129",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0010",
      tUrl: "XX0010",
      type: "letter",
      multiColor: true,
    },

    {
      id: "130",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0011",
      tUrl: "XX0011",
      type: "letter",
      multiColor: true,
    },
    {
      id: "131",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0012",
      tUrl: "XX0012",
      type: "letter",
      multiColor: true,
    },
    {
      id: "132",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0013",
      tUrl: "XX0013",
      type: "letter",
      multiColor: true,
    },

    {
      id: "133",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0014",
      tUrl: "XX0014",
      type: "letter",
      multiColor: true,
    },
    {
      id: "134",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0015",
      tUrl: "XX0015",
      type: "letter",
      multiColor: true,
    },
    {
      id: "135",
      category: ":2:3:",
      text: "happy",
      status: false,
      url: "XX0016",
      tUrl: "XX0016",
      type: "letter",
      multiColor: true,
    },
    {
      id: "136",
      category: ":2:3:",
      text: "happy",
      status: true,
      url: "XX0017",
      tUrl: "XX0017",
      type: "letter",
      multiColor: true,
    },

    {
      id: "137",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0020",
      tUrl: "XX0020",
      type: "letter",
      multiColor: true,
    },
    {
      id: "138",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0021",
      tUrl: "XX0021",
      type: "letter",
      multiColor: true,
    },
    {
      id: "139",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0022",
      tUrl: "XX0022",
      type: "letter",
      multiColor: true,
    },
    {
      id: "140",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0023",
      tUrl: "XX0023",
      type: "letter",
      multiColor: true,
    },
    {
      id: "141",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0024",
      tUrl: "XX0024",
      type: "letter",
      multiColor: true,
    },
    {
      id: "142",
      category: ":10:",
      text: "love",
      status: true,
      url: "XX0025",
      tUrl: "XX0025",
      type: "letter",
      multiColor: true,
    },

    {
      id: "143",
      category: ":10:5:",
      text: "Эмэгтэйчүүдийн баяр",
      status: true,
      url: "XX0030",
      tUrl: "XX0030",
      type: "letter",
      multiColor: true,
    },

    {
      id: "144",
      category: ":10:5:",
      text: "Эмэгтэйчүүдийн баяр",
      status: true,
      url: "XX0031",
      tUrl: "XX0031",
      type: "letter",
      multiColor: true,
    },
    {
      id: "145",
      category: ":10:5:",
      text: "Эмэгтэйчүүдийн баяр",
      status: true,
      url: "XX0032",
      tUrl: "XX0032",
      type: "letter",
      multiColor: true,
    },
    {
      id: "146",
      category: ":10:5:",
      text: "Эмэгтэйчүүдийн баяр",
      status: true,
      url: "XX0033",
      tUrl: "XX0033",
      type: "letter",
      multiColor: true,
    },

    {
      id: "147",
      category: ":14:6:",
      text: "Спорт арга хэмжээ",
      status: true,
      url: "XX0040",
      tUrl: "XX0040",
      type: "letter",
      multiColor: true,
    },

    {
      id: "148",
      category: ":14:6:",
      text: "Спорт арга хэмжээ",
      status: true,
      url: "XX0041",
      tUrl: "XX0041",
      type: "letter",
      multiColor: true,
    },
    {
      id: "149",
      category: ":14:6:",
      text: "Спорт арга хэмжээ",
      status: true,
      url: "XX0042",
      tUrl: "XX0042",
      type: "letter",
      multiColor: true,
    },
    {
      id: "150",
      category: ":14:6:",
      text: "Спорт арга хэмжээ",
      status: true,
      url: "XX0043",
      tUrl: "XX0043",
      type: "letter",
      multiColor: true,
    },

    {
      id: "151",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0050",
      tUrl: "XX0050",
      type: "letter",
      multiColor: true,
    },

    {
      id: "152",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0051",
      tUrl: "XX0051",
      type: "letter",
      multiColor: true,
    },

    {
      id: "153",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0052",
      tUrl: "XX0052",
      type: "letter",
      multiColor: true,
    },
    {
      id: "154",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0053",
      tUrl: "XX0053",
      type: "letter",
      multiColor: true,
    },

    {
      id: "155",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0054",
      tUrl: "XX0054",
      type: "letter",
      multiColor: true,
    },

    {
      id: "156",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0055",
      tUrl: "XX0055",
      type: "letter",
      multiColor: true,
    },

    {
      id: "157",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0056",
      tUrl: "XX0056",
      type: "letter",
      multiColor: true,
    },

    {
      id: "158",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0057",
      tUrl: "XX0057",
      type: "letter",
      multiColor: true,
    },

    {
      id: "159",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0058",
      tUrl: "XX0058",
      type: "letter",
      multiColor: true,
    },

    {
      id: "160",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0059",
      tUrl: "XX0059",
      type: "letter",
      multiColor: true,
    },

    {
      id: "161",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0060",
      tUrl: "XX0060",
      type: "letter",
      multiColor: true,
    },

    {
      id: "162",
      category: ":6:",
      text: "Эрдмийн баяр",
      status: true,
      url: "XX0061",
      tUrl: "XX0061",
      type: "letter",
      multiColor: true,
    },

    {
      id: "163",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0070",
      tUrl: "XX0070",
      type: "letter",
      multiColor: true,
    },

    {
      id: "164",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0071",
      tUrl: "XX0071",
      type: "letter",
      multiColor: true,
    },

    {
      id: "165",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0072",
      tUrl: "XX0072",
      type: "letter",
      multiColor: true,
    },

    {
      id: "166",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0080",
      tUrl: "XX0080",
      type: "letter",
      multiColor: true,
    },

    {
      id: "167",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0081",
      tUrl: "XX0081",
      type: "letter",
      multiColor: true,
    },

    {
      id: "168",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0082",
      tUrl: "XX0082",
      type: "letter",
      multiColor: true,
    },

    {
      id: "169",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0083",
      tUrl: "XX0083",
      type: "letter",
      multiColor: true,
    },

    {
      id: "170",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0084",
      tUrl: "XX0084",
      type: "letter",
      multiColor: true,
    },
    {
      id: "171",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0085",
      tUrl: "XX0085",
      type: "letter",
      multiColor: true,
    },

    {
      id: "172",
      category: ":6:",
      text: "Ногоон гэрэл цагаан шугам",
      status: true,
      url: "XX0086",
      tUrl: "XX0086",
      type: "letter",
      multiColor: true,
    },
  ],
  BannerPosition: [1],
  Bank: [1, 2, 3, 4],
  DesignerLevel: [0, 1, 2, 3, 4, 5],
  DesignerFee: {
    level1: 55,
    level2: 60,
    level3: 65,
    level4: 70,
    level5: 75,
  },
  Tags: [
    {
      id: '1-1',
      tags: ['Trendy', 'Trend', 'Загвар', 'Шинэлэг', 'Тренд', 'Орчин үеийн', 'Шинэ', 'Загварлаг'],
    },
    {
      id: '1-2',
      tags: ['Fb', 'post', 'Facebook', 'Фэйсбүүк', 'Media', 'Social', 'Marketing', 'сошиал'],
    },
    {
      id: '1-3',
      tags: [
        'Fb',
        'post',
        'Facebook',
        'Фэйсбүүк',
        'Media',
        'Social',
        'Marketing',
        'сошиал',
        'Story',
      ],
    },
    {
      id: '1-4',
      tags: [
        'Fb',
        'post',
        'Facebook',
        'Фэйсбүүк',
        'Media',
        'Social',
        'Marketing',
        'сошиал',
        'Event',
      ],
    },
    {
      id: '1-5',
      tags: ['Fb', 'post', 'Facebook', 'Фэйсбүүк', 'Media', 'Social', 'Marketing', 'сошиал'],
    },
    {
      id: '1-6',
      tags: ['Instagram', 'post', 'Insta', 'Story', 'Social', 'Media', 'Marketing'],
    },
    {
      id: '1-7',
      tags: ['Instagram', 'post', 'Insta', 'Story', 'Social', 'Media', 'Story'],
    },
    {
      id: '1-8',
      tags: ['Титэм үгс', 'quotes', 'quote', 'ишлэл', 'үгс', 'twitter'],
    },
    {
      id: '1-9',
      tags: ['youtube', 'YT', 'video', 'media'],
    },
    {
      id: '1-10',
      tags: ['үгс', 'онч үгс', 'quates', 'word'],
    },
    {
      id: '2-1',
      tags: ['Постер', 'Poster', 'Fb post', 'Insta post'],
    },
    {
      id: '2-2',
      tags: ['Урилга', 'Invitation', 'Invite', 'Urilga'],
    },
    {
      id: '2-3',
      tags: ['Мэндчилгээ', 'Mendchilgee', 'Greetings'],
    },
    {
      id: '2-4',
      tags: [
        'Өргөмжлөл',
        'Certificate',
        'Achievement',
        'Accomplishment',
        'Urgumjlul',
        'Batlamj',
        'Батламж',
      ],
    },
    {
      id: '2-5',
      tags: ['Меню', 'Menu', 'Цэс'],
    },
    {
      id: '2-6',
      tags: ['Брошур', 'Brochure', 'Folded', 'brochure', 'Танилцуулга', 'Broshur'],
    },
    {
      id: '2-7',
      tags: [
        'Ажилд авах зар',
        'Hiring',
        'Work',
        'HR',
        'Job',
        'Ажил',
        'Зар',
        'Ажлын зар',
        'Ажилд авна',
        'CV',
      ],
    },
    {
      id: '2-8',
      tags: ['Нэрийн хуудас', 'Namecard', 'Card', 'Business', 'Картны загвар'],
    },
    {
      id: '2-9',
      tags: ['Инфографик', 'Infographic', 'Статистик', 'Үзүүлэлт', 'Statistic'],
    },
    {
      id: '2-10',
      tags: ['Анкет', 'CV', 'Resume', 'Ажлын анкет', 'Анкет	товч танилцуулга'],
    },
    {
      id: '2-11',
      tags: ['Ажлын үнэмлэх', 'Employee card', 'Card', 'Namecard', 'Worker card', 'Үнэмлэх', 'ID'],
    },
    {
      id: '2-12',
      tags: ['Эрхийн бичиг', 'erhiin bichig', 'erkhiin bichig'],
    },
    {
      id: '2-13',
      tags: ['Гэрчилгээ', 'gerchilgee'],
    },
    {
      id: '2-14',
      tags: ['Presentation', 'PPT'],
    },
  ],
  EmployeeType: ['editor', 'admin'],
  UserType: ['designer', 'user'],
  WebUserType: [0, 1],
  PrivLevel: ['1', '2', '3', '4'],
  ElementCategory: [1, 2, 3, 4],
  ElementSubCategory: [
    {
      parent: 1,
      id: 1,
    },
    {
      parent: 1,
      id: 2,
    },
    {
      parent: 1,
      id: 3,
    },
    {
      parent: 1,
      id: 4,
    },
    {
      parent: 1,
      id: 5,
    },
    {
      parent: 1,
      id: 6,
    },
    {
      parent: 1,
      id: 7,
    },
    {
      parent: 1,
      id: 8,
    },
    {
      parent: 1,
      id: 9,
    },
    {
      parent: 2,
      id: 1,
    },
    {
      parent: 2,
      id: 2,
    },
    {
      parent: 2,
      id: 3,
    },
    {
      parent: 2,
      id: 4,
    },
    {
      parent: 2,
      id: 5,
    },
    {
      parent: 3,
      id: 1,
    },
    {
      parent: 3,
      id: 2,
    },
    {
      parent: 4,
      id: 1,
    },
    {
      parent: 4,
      id: 2,
    },
    {
      parent: 4,
      id: 3,
    },
    {
      parent: 4,
      id: 4,
    },
    {
      parent: 4,
      id: 5,
    },
    {
      parent: 4,
      id: 6,
    },
    {
      parent: 4,
      id: 7,
    },
  ],
  orgElementCategory: [1, 2, 3, 4, 5, 6, 7],
  FontCategory: [1, 2],
  FontSubCategory: [
    {
      parent: 1,
      id: 1,
    },
    {
      parent: 1,
      id: 2,
    },
    {
      parent: 1,
      id: 3,
    },
    {
      parent: 1,
      id: 4,
    },
    {
      parent: 2,
      id: 1,
    },
    {
      parent: 2,
      id: 2,
    },
    {
      parent: 2,
      id: 3,
    },
    {
      parent: 2,
      id: 4,
    },
  ],
  Category: [1, 2, 3, 4, 6],
  SubCategory: [
    {
      parent: 1,
      id: 1,
    },
    {
      parent: 1,
      id: 2,
    },
    {
      parent: 1,
      id: 3,
    },
    {
      parent: 1,
      id: 4,
    },
    {
      parent: 1,
      id: 5,
    },
    {
      parent: 1,
      id: 6,
    },
    {
      parent: 1,
      id: 7,
    },
    {
      parent: 1,
      id: 8,
    },
    {
      parent: 1,
      id: 9,
    },
    {
      parent: 1,
      id: 10,
    },

    {
      parent: 2,
      id: 1,
    },
    {
      parent: 2,
      id: 2,
    },
    {
      parent: 2,
      id: 3,
    },
    {
      parent: 2,
      id: 4,
    },
    {
      parent: 2,
      id: 5,
    },
    {
      parent: 2,
      id: 6,
    },
    {
      parent: 2,
      id: 7,
    },
    {
      parent: 2,
      id: 8,
    },
    {
      parent: 2,
      id: 9,
    },
    {
      parent: 2,
      id: 10,
    },
    {
      parent: 2,
      id: 11,
    },
    {
      parent: 2,
      id: 12,
    },
    {
      parent: 2,
      id: 13,
    },

    {
      parent: 3,
      id: 1,
    },
    {
      parent: 3,
      id: 2,
    },
    {
      parent: 3,
      id: 3,
    },
    {
      parent: 3,
      id: 4,
    },
    {
      parent: 3,
      id: 5,
    },
    {
      parent: 3,
      id: 6,
    },

    {
      parent: 4,
      id: 1,
    },
    {
      parent: 4,
      id: 2,
    },
    {
      parent: 4,
      id: 3,
    },
    {
      parent: 4,
      id: 4,
    },
    {
      parent: 4,
      id: 5,
    },

    {
      parent: 6,
      id: 1,
    },
    {
      parent: 6,
      id: 2,
    },
    {
      parent: 6,
      id: 3,
    },
    {
      parent: 6,
      id: 4,
    },
    {
      parent: 6,
      id: 5,
    },
    {
      parent: 6,
      id: 6,
    },
    {
      parent: 6,
      id: 7,
    },
    {
      parent: 6,
      id: 8,
    },
    {
      parent: 6,
      id: 9,
    },
    {
      parent: 6,
      id: 10,
    },
    {
      parent: 6,
      id: 11,
    },
    {
      parent: 6,
      id: 12,
    },
    {
      parent: 6,
      id: 13,
    },
    {
      parent: 6,
      id: 14,
    },
    {
      parent: 6,
      id: 15,
    },
  ],
  SubCategorySegment: [
    {
      node: 3,
      parent: 1,
      id: 1,
    },
    {
      node: 3,
      parent: 1,
      id: 2,
    },
    {
      node: 3,
      parent: 1,
      id: 3,
    },
    {
      node: 3,
      parent: 1,
      id: 4,
    },
    {
      node: 3,
      parent: 1,
      id: 5,
    },
    {
      node: 3,
      parent: 1,
      id: 6,
    },
    {
      node: 3,
      parent: 1,
      id: 7,
    },
    {
      node: 3,
      parent: 1,
      id: 8,
    },
    {
      node: 3,
      parent: 1,
      id: 9,
    },
    {
      node: 3,
      parent: 1,
      id: 10,
    },
    {
      node: 3,
      parent: 1,
      id: 11,
    },
    {
      node: 3,
      parent: 1,
      id: 12,
    },
    {
      node: 3,
      parent: 1,
      id: 13,
    },
    {
      node: 3,
      parent: 1,
      id: 14,
    },
    {
      node: 3,
      parent: 1,
      id: 15,
    },
    {
      node: 3,
      parent: 1,
      id: 16,
    },

    {
      node: 3,
      parent: 2,
      id: 1,
    },
    {
      node: 3,
      parent: 2,
      id: 2,
    },
    {
      node: 3,
      parent: 2,
      id: 3,
    },
    {
      node: 3,
      parent: 2,
      id: 4,
    },
    {
      node: 3,
      parent: 2,
      id: 5,
    },
    {
      node: 3,
      parent: 2,
      id: 6,
    },
    {
      node: 3,
      parent: 2,
      id: 7,
    },
    {
      node: 3,
      parent: 2,
      id: 8,
    },
    {
      node: 3,
      parent: 2,
      id: 9,
    },
    {
      node: 3,
      parent: 2,
      id: 10,
    },
    {
      node: 3,
      parent: 2,
      id: 11,
    },
    {
      node: 3,
      parent: 2,
      id: 12,
    },
    {
      node: 3,
      parent: 2,
      id: 13,
    },
    {
      node: 3,
      parent: 2,
      id: 14,
    },
    {
      node: 3,
      parent: 2,
      id: 15,
    },
    {
      node: 3,
      parent: 2,
      id: 16,
    },
    {
      node: 3,
      parent: 2,
      id: 17,
    },
    {
      node: 3,
      parent: 2,
      id: 18,
    },
    {
      node: 3,
      parent: 2,
      id: 19,
    },

    {
      node: 3,
      parent: 3,
      id: 1,
    },
    {
      node: 3,
      parent: 3,
      id: 2,
    },
    {
      node: 3,
      parent: 3,
      id: 3,
    },
    {
      node: 3,
      parent: 3,
      id: 4,
    },
    {
      node: 3,
      parent: 3,
      id: 5,
    },
    {
      node: 3,
      parent: 3,
      id: 6,
    },
    {
      node: 3,
      parent: 3,
      id: 7,
    },
    {
      node: 3,
      parent: 3,
      id: 8,
    },
    {
      node: 3,
      parent: 3,
      id: 9,
    },
    {
      node: 3,
      parent: 3,
      id: 10,
    },
    {
      node: 3,
      parent: 3,
      id: 11,
    },

    {
      node: 3,
      parent: 4,
      id: 1,
    },
    {
      node: 3,
      parent: 4,
      id: 2,
    },
    {
      node: 3,
      parent: 4,
      id: 3,
    },
    {
      node: 3,
      parent: 4,
      id: 4,
    },
    {
      node: 3,
      parent: 4,
      id: 5,
    },
    {
      node: 3,
      parent: 4,
      id: 6,
    },
    {
      node: 3,
      parent: 4,
      id: 7,
    },
    {
      node: 3,
      parent: 4,
      id: 8,
    },

    {
      node: 3,
      parent: 5,
      id: 1,
    },
    {
      node: 3,
      parent: 5,
      id: 2,
    },
    {
      node: 3,
      parent: 5,
      id: 3,
    },

    {
      node: 3,
      parent: 6,
      id: 1,
    },
    {
      node: 3,
      parent: 6,
      id: 2,
    },
    {
      node: 3,
      parent: 6,
      id: 3,
    },

    {
      node: 3,
      parent: 7,
      id: 1,
    },
    {
      node: 3,
      parent: 7,
      id: 2,
    },
    {
      node: 3,
      parent: 7,
      id: 3,
    },
    {
      node: 3,
      parent: 7,
      id: 4,
    },
    {
      node: 3,
      parent: 7,
      id: 5,
    },
  ],
  Dynamic: ['z1', 'z2'],
  TemplatePrice: [
    {price: 0, level: 0},
    {price: 100, level: 100},
    {price: 3000, level: 1},
    {price: 4000, level: 1},
    {price: 5000, level: 1},
    {price: 8000, level: 2},
    {price: 9000, level: 2},
    {price: 10000, level: 2},
    {price: 15000, level: 3},
    {price: 20000, level: 3},
  ],
  ElementPrice: [
    {price: 0, level: 0},
    {price: 100, level: 1},
    {price: 200, level: 1},
    {price: 300, level: 1},
    {price: 500, level: 2},
    {price: 600, level: 2},
    {price: 700, level: 2},
    {price: 800, level: 2},
    {price: 900, level: 2},
    {price: 1000, level: 3},
    {price: 1100, level: 3},
    {price: 1200, level: 3},
    {price: 1300, level: 3},
    {price: 1400, level: 3},
    {price: 1500, level: 3},
    {price: 2000, level: 4},
    {price: 3000, level: 4},
    {price: 4000, level: 4},
    {price: 5000, level: 4},
  ],
  news: [1, 2, 3, 4, 5, 6, 7, 8],

  BrandbookLogos: {
    position: [1, 2],
    lang: [0, 1, 2],
    status: [0, 1],
    logoType: [0, 1],
    colorType: [0, 1, 2],
  },
  BrandbookColors: {
    torol: [0, 1, 2],
  },
  address_city: [
    {id: 1, name: 'Улаанбаатар'},
    {id: 2, name: 'Архангай'},
    {id: 3, name: 'Баян-Өлгий'},
    {id: 4, name: 'Баянхонгор'},
    {id: 5, name: 'Булган'},
    {id: 6, name: 'Говь-Алтай'},
    {id: 7, name: 'Говьсүмбэр'},
    {id: 8, name: 'Дархан-Уул'},
    {id: 9, name: 'Дорноговь'},
    {id: 10, name: 'Дорнод'},
    {id: 11, name: 'Дундговь'},
    {id: 12, name: 'Завхан'},
    {id: 13, name: 'Орхон'},
    {id: 14, name: 'Өвөрхангай'},
    {id: 15, name: 'Өмнөговь'},
    {id: 16, name: 'Сүхбаатар'},
    {id: 17, name: 'Сэлэнгэ'},
    {id: 18, name: 'Төв'},
    {id: 19, name: 'Увс'},
    {id: 20, name: 'Ховд'},
    {id: 21, name: 'Хөвсгөл'},
    {id: 22, name: 'Хэнтий'},
  ],
  address_district: [
    // arkhangai aimag
    {city_id: 2, id: 1, name: 'Эрдэнэбулган'},
    {city_id: 2, id: 2, name: 'Батцэнгэл'},
    {city_id: 2, id: 3, name: 'Булган'},
    {city_id: 2, id: 4, name: 'Жаргалант'},
    {city_id: 2, id: 5, name: 'Их Тамир'},
    {city_id: 2, id: 6, name: 'Тариат'},
    {city_id: 2, id: 7, name: 'Өгийнуур'},
    {city_id: 2, id: 8, name: 'Өлзийт'},
    {city_id: 2, id: 9, name: 'Өндөр-Улаан'},
    {city_id: 2, id: 10, name: 'Хотонт'},
    {city_id: 2, id: 11, name: 'Төвшрүүлэх'},
    {city_id: 2, id: 12, name: 'Хайрхан'},
    {city_id: 2, id: 13, name: 'Хангай'},
    {city_id: 2, id: 14, name: 'Хашаат'},
    {city_id: 2, id: 15, name: 'Цахир'},
    {city_id: 2, id: 16, name: 'Цэнхэр'},
    {city_id: 2, id: 17, name: 'Цэцэрлэг'},
    {city_id: 2, id: 18, name: 'Чулуут'},
    {city_id: 2, id: 19, name: 'Эрдэнэмандал'},
    //ulaanbaatar
    {city_id: 1, id: 1, name: 'Багануур'},
    {city_id: 1, id: 2, name: 'Багахангай'},
    {city_id: 1, id: 3, name: 'Баянгол'},
    {city_id: 1, id: 4, name: 'Сүхбаатар'},
    {city_id: 1, id: 5, name: 'Баянзүрх'},
    {city_id: 1, id: 6, name: 'Хан-Уул'},
    {city_id: 1, id: 7, name: 'Налайх'},
    {city_id: 1, id: 8, name: 'Сонгинохайрхан'},
    {city_id: 1, id: 9, name: 'Чингэлтэй'},
    //bayan-olgii
    {city_id: 3, id: 1, name: 'Алтай'},
    {city_id: 3, id: 2, name: 'Алтанцөгц'},
    {city_id: 3, id: 3, name: 'Өлгий'},
    {city_id: 3, id: 4, name: 'Баяннуур'},
    {city_id: 3, id: 5, name: 'Бугат'},
    {city_id: 3, id: 6, name: 'Булган'},
    {city_id: 3, id: 7, name: 'Буянт'},
    {city_id: 3, id: 8, name: 'Сагсай'},
    {city_id: 3, id: 9, name: 'Дэлүүн'},
    {city_id: 3, id: 10, name: 'Ногооннуур'},
    {city_id: 3, id: 11, name: 'Цэнгэл'},
    {city_id: 3, id: 12, name: 'Толбо'},
    {city_id: 3, id: 13, name: 'Улаанхус'},
    //bayankhongor
    {city_id: 4, id: 1, name: 'Баянхонгор'},
    {city_id: 4, id: 2, name: 'Баацагаан'},
    {city_id: 4, id: 3, name: 'Баян-Овоо'},
    {city_id: 4, id: 4, name: 'Баян-Өндөр'},
    {city_id: 4, id: 5, name: 'Баянбулаг'},
    {city_id: 4, id: 6, name: 'Бууцагаан'},
    {city_id: 4, id: 7, name: 'Баянговь'},
    {city_id: 4, id: 8, name: 'Баянлиг'},
    {city_id: 4, id: 9, name: 'Баянцагаан'},
    {city_id: 4, id: 10, name: 'Богд'},
    {city_id: 4, id: 11, name: 'Бөмбөгөр'},
    {city_id: 4, id: 12, name: 'Галуут'},
    {city_id: 4, id: 13, name: 'Гурванбулаг'},
    {city_id: 4, id: 14, name: 'Жаргалант'},
    {city_id: 4, id: 15, name: 'Жинст'},
    {city_id: 4, id: 16, name: 'Заг'},
    {city_id: 4, id: 17, name: 'Өлзийт'},
    {city_id: 4, id: 18, name: 'Хүрээмарал'},
    {city_id: 4, id: 19, name: 'Шинэжинст'},
    {city_id: 4, id: 20, name: 'Эрдэнэцогт'},
    //bulgan
    {city_id: 5, id: 1, name: 'Булган'},
    {city_id: 5, id: 2, name: 'Баян-Агт'},
    {city_id: 5, id: 3, name: 'Баяннуур'},
    {city_id: 5, id: 4, name: 'Бугат'},
    {city_id: 5, id: 5, name: 'Бүрэгхангай'},
    {city_id: 5, id: 6, name: 'Гурван Булаг'},
    {city_id: 5, id: 7, name: 'Дашинчилэн'},
    {city_id: 5, id: 8, name: 'Могод'},
    {city_id: 5, id: 9, name: 'Орхон'},
    {city_id: 5, id: 10, name: 'Рашаант'},
    {city_id: 5, id: 11, name: 'Сайхан'},
    {city_id: 5, id: 12, name: 'Сэлэнгэ'},
    {city_id: 5, id: 13, name: 'Тэшиг'},
    {city_id: 5, id: 14, name: 'Хутаг-Өндөр'},
    {city_id: 5, id: 15, name: 'Хангал'},
    {city_id: 5, id: 16, name: 'Хишиг-Өндөр'},
    // gobi-altai
    {city_id: 6, id: 1, name: 'Есөнбулаг'},
    {city_id: 6, id: 2, name: 'Алтай'},
    {city_id: 6, id: 3, name: 'Тонхил'},
    {city_id: 6, id: 4, name: 'Баян-Уул'},
    {city_id: 6, id: 5, name: 'Цогт'},
    {city_id: 6, id: 6, name: 'Бигэр'},
    {city_id: 6, id: 7, name: 'Бугат'},
    {city_id: 6, id: 8, name: 'Дэлгэр'},
    {city_id: 6, id: 9, name: 'Дарив'},
    {city_id: 6, id: 10, name: 'Жаргалан'},
    {city_id: 6, id: 11, name: 'Халиун'},
    {city_id: 6, id: 12, name: 'Тайшир'},
    {city_id: 6, id: 13, name: 'Төгрөг'},
    {city_id: 6, id: 14, name: 'Хөхморьт'},
    {city_id: 6, id: 15, name: 'Цээл'},
    {city_id: 6, id: 16, name: 'Чандмань'},
    {city_id: 6, id: 17, name: 'Шарга'},
    {city_id: 6, id: 18, name: 'Эрдэнэ'},
    // gobisumber
    {city_id: 7, id: 1, name: 'Сүмбэр'},
    //darhan
    {city_id: 8, id: 1, name: 'Дархан'},
    {city_id: 8, id: 2, name: 'Хонгор'},
    {city_id: 8, id: 3, name: 'Шарын Гол'},
    {city_id: 8, id: 4, name: 'Орхон'},
    //dornogobi
    {city_id: 9, id: 1, name: 'Айраг'},
    {city_id: 9, id: 2, name: 'Алтанширээ'},
    {city_id: 9, id: 3, name: 'Даланжаргалан'},
    {city_id: 9, id: 4, name: 'Дэлгэрэх'},
    {city_id: 9, id: 5, name: 'Замын-Үүд'},
    {city_id: 9, id: 6, name: 'Иххэт'},
    {city_id: 9, id: 7, name: 'Мандах'},
    {city_id: 9, id: 8, name: 'Өргөн'},
    {city_id: 9, id: 9, name: 'Сайншанд'},
    {city_id: 9, id: 10, name: 'Сайхандулаан'},
    {city_id: 9, id: 11, name: 'Улаанбадрах'},
    {city_id: 9, id: 12, name: 'Хатанбулаг'},
    {city_id: 9, id: 13, name: 'Хөвсгөл'},
    {city_id: 9, id: 14, name: 'Эрдэнэ'},
    //dornod
    {city_id: 10, id: 1, name: 'Хэрлэн'},
    {city_id: 10, id: 2, name: 'Баян-Уул'},
    {city_id: 10, id: 3, name: 'Баяндун'},
    {city_id: 10, id: 4, name: 'Баянтүмэн'},
    {city_id: 10, id: 5, name: 'Булган'},
    {city_id: 10, id: 6, name: 'Гурванзагал'},
    {city_id: 10, id: 7, name: 'Дашбалбар'},
    {city_id: 10, id: 8, name: 'Матад'},
    {city_id: 10, id: 9, name: 'Сэргэлэн'},
    {city_id: 10, id: 10, name: 'Халхгол'},
    {city_id: 10, id: 11, name: 'Хөлөнбуйр'},
    {city_id: 10, id: 12, name: 'Цагаан-Овоо'},
    {city_id: 10, id: 13, name: 'Чойбалсан'},
    {city_id: 10, id: 14, name: 'Чулуунхороот'},
    //dundgobi
    {city_id: 11, id: 1, name: 'Адаацаг'},
    {city_id: 11, id: 2, name: 'Баянжаргалан'},
    {city_id: 11, id: 3, name: 'Сайнцагаан'},
    {city_id: 11, id: 4, name: 'Говь-Угтаал'},
    {city_id: 11, id: 5, name: 'Гурвансайхан'},
    {city_id: 11, id: 6, name: 'Дэлгэрхангай'},
    {city_id: 11, id: 7, name: 'Дэлгэрцогт'},
    {city_id: 11, id: 8, name: 'Дэрэн'},
    {city_id: 11, id: 9, name: 'Луус'},
    {city_id: 11, id: 10, name: 'Өлзийт'},
    {city_id: 11, id: 11, name: 'Өндөршил'},
    {city_id: 11, id: 12, name: 'Сайхан-Овоо'},
    {city_id: 11, id: 13, name: 'Хулд'},
    {city_id: 11, id: 14, name: 'Цагаандэлгэр'},
    {city_id: 11, id: 15, name: 'Эрдэнэдалай'},
    //zavkhan
    {city_id: 12, id: 1, name: 'Улиастай'},
    {city_id: 12, id: 2, name: 'Алдархаан'},
    {city_id: 12, id: 3, name: 'Асгат'},
    {city_id: 12, id: 4, name: 'Баянтэс'},
    {city_id: 12, id: 5, name: 'Дөрвөлжин'},
    {city_id: 12, id: 6, name: 'Завханмандал'},
    {city_id: 12, id: 7, name: 'Идэр'},
    {city_id: 12, id: 8, name: 'Их-Уул'},
    {city_id: 12, id: 9, name: 'Нөмрөг'},
    {city_id: 12, id: 10, name: 'Отгон'},
    {city_id: 12, id: 15, name: 'Сантмаргац'},
    {city_id: 12, id: 11, name: 'Сонгино'},
    {city_id: 12, id: 12, name: 'Тосонцэнгэл'},
    {city_id: 12, id: 13, name: 'Түдэвтэй'},
    {city_id: 12, id: 14, name: 'Тэлмэн'},
    {city_id: 12, id: 15, name: 'Тэс'},
    {city_id: 12, id: 16, name: 'Ургамал'},
    {city_id: 12, id: 17, name: 'Цагаанхайрхан'},
    {city_id: 12, id: 18, name: 'Цагаанчулуут'},
    {city_id: 12, id: 19, name: 'Цэцэн-Уул'},
    {city_id: 12, id: 20, name: 'Шилүүстэй'},
    {city_id: 12, id: 21, name: 'Эрдэнэхайрхан'},
    {city_id: 12, id: 21, name: 'Яруу'},
    //orkhon
    {city_id: 13, id: 1, name: 'Баян-Өндөр'},
    {city_id: 13, id: 2, name: 'Жаргалант'},
    //ovorhangai
    {city_id: 14, id: 1, name: 'Арвайхээр'},
    {city_id: 14, id: 2, name: 'Баруунбаян-Улаан'},
    {city_id: 14, id: 3, name: 'Бат-Өлзий'},
    {city_id: 14, id: 4, name: 'Баян-Өндөр'},
    {city_id: 14, id: 5, name: 'Баянгол'},
    {city_id: 14, id: 6, name: 'Богд'},
    {city_id: 14, id: 7, name: 'Бүрд'},
    {city_id: 14, id: 8, name: 'Гучин-Ус'},
    {city_id: 14, id: 9, name: 'Есөн Зүйл'},
    {city_id: 14, id: 10, name: 'Зүүнбаян-Улаан'},
    {city_id: 14, id: 11, name: 'Нарийнтээл'},
    {city_id: 14, id: 12, name: 'Өлзийт'},
    {city_id: 14, id: 13, name: 'Сант'},
    {city_id: 14, id: 14, name: 'Тарагт'},
    {city_id: 14, id: 15, name: 'Төгрөг'},
    {city_id: 14, id: 16, name: 'Уянга'},
    {city_id: 14, id: 17, name: 'Хайрхандулаан'},
    {city_id: 14, id: 18, name: 'Хархорин'},
    {city_id: 14, id: 19, name: 'Хужирт'},
    //omnogobi
    {city_id: 15, id: 1, name: 'Баян-Овоо'},
    {city_id: 15, id: 2, name: 'Баяндалай'},
    {city_id: 15, id: 3, name: 'Булган'},
    {city_id: 15, id: 4, name: 'Гурвантэс'},
    {city_id: 15, id: 5, name: 'Даланзадгад'},
    {city_id: 15, id: 6, name: 'Мандал-Овоо'},
    {city_id: 15, id: 7, name: 'Манлай'},
    {city_id: 15, id: 8, name: 'Ноён'},
    {city_id: 15, id: 9, name: 'Номгон'},
    {city_id: 15, id: 10, name: 'Сэврэй'},
    {city_id: 15, id: 11, name: 'Ханхонгор'},
    {city_id: 15, id: 12, name: 'Ханбогд'},
    {city_id: 15, id: 13, name: 'Хүрмэн'},
    {city_id: 15, id: 14, name: 'Цогт-Овоо'},
    {city_id: 15, id: 15, name: 'Цогтцэций'},
    //sukhbaatar
    {city_id: 16, id: 1, name: 'Баруун-Урт'},
    {city_id: 16, id: 2, name: 'Асгат'},
    {city_id: 16, id: 3, name: 'Баяндэлгэр'},
    {city_id: 16, id: 4, name: 'Дарьганга'},
    {city_id: 16, id: 5, name: 'Мөнххаан'},
    {city_id: 16, id: 6, name: 'Наран'},
    {city_id: 16, id: 7, name: 'Онгон'},
    {city_id: 16, id: 8, name: 'Сүхбаатар'},
    {city_id: 16, id: 9, name: 'Түвшинширээ'},
    {city_id: 16, id: 10, name: 'Түмэнцогт'},
    {city_id: 16, id: 11, name: 'Уулбаян'},
    {city_id: 16, id: 12, name: 'Халзан'},
    {city_id: 16, id: 13, name: 'Эрдэнэцагаан'},
    //selenge
    {city_id: 17, id: 1, name: 'Алтанбулаг'},
    {city_id: 17, id: 2, name: 'Баруунбүрэн'},
    {city_id: 17, id: 3, name: 'Баянгол'},
    {city_id: 17, id: 4, name: 'Ерөө'},
    {city_id: 17, id: 5, name: 'Шаамар'},
    {city_id: 17, id: 6, name: 'Жавхлант'},
    {city_id: 17, id: 7, name: 'Зүүнбүрэн'},
    {city_id: 17, id: 8, name: 'Мандал'},
    {city_id: 17, id: 9, name: 'Сүхбаатар'},
    {city_id: 17, id: 10, name: 'Сайхан'},
    {city_id: 17, id: 11, name: 'Орхон'},
    {city_id: 17, id: 12, name: 'Орхонтуул'},
    {city_id: 17, id: 13, name: 'Сант'},
    {city_id: 17, id: 14, name: 'Түшиг'},
    {city_id: 17, id: 15, name: 'Хушаат'},
    {city_id: 17, id: 16, name: 'Хүдэр'},
    {city_id: 17, id: 17, name: 'Цагааннуур'},
    //tov aimag
    {city_id: 18, id: 1, name: 'Зуунмод'},
    {city_id: 18, id: 2, name: 'Алтанбулаг'},
    {city_id: 18, id: 3, name: 'Аргалант'},
    {city_id: 18, id: 4, name: 'Архуст'},
    {city_id: 18, id: 5, name: 'Батсүмбэр'},
    {city_id: 18, id: 6, name: 'Баян'},
    {city_id: 18, id: 7, name: 'Баян-Өнжүүл'},
    {city_id: 18, id: 8, name: 'Баяндэлгэр'},
    {city_id: 18, id: 9, name: 'Баянжаргалан'},
    {city_id: 18, id: 10, name: 'Баянхангай'},
    {city_id: 18, id: 11, name: 'Баянцагаан'},
    {city_id: 18, id: 12, name: 'Баянцогт'},
    {city_id: 18, id: 13, name: 'Баянчандмань'},
    {city_id: 18, id: 14, name: 'Борнуур'},
    {city_id: 18, id: 15, name: 'Бүрэн'},
    {city_id: 18, id: 16, name: 'Дэлгэрхаан'},
    {city_id: 18, id: 17, name: 'Жаргалант'},
    {city_id: 18, id: 18, name: 'Заамар'},
    {city_id: 18, id: 19, name: 'Лүн'},
    {city_id: 18, id: 20, name: 'Мөнгөнморьт'},
    {city_id: 18, id: 21, name: 'Өндөрширээт'},
    {city_id: 18, id: 22, name: 'Сүмбэр'},
    {city_id: 18, id: 23, name: 'Сэргэлэн'},
    {city_id: 18, id: 24, name: 'Угтаалцайдам'},
    {city_id: 18, id: 25, name: 'Цээл'},
    {city_id: 18, id: 26, name: 'Эрдэнэ'},
    {city_id: 18, id: 27, name: 'Эрдэнэсант'},
    //uvs
    {city_id: 19, id: 1, name: 'Улаангом'},
    {city_id: 19, id: 2, name: 'Баруунтуруун'},
    {city_id: 19, id: 3, name: 'Бөхмөрөн'},
    {city_id: 19, id: 4, name: 'Давст'},
    {city_id: 19, id: 5, name: 'Өмнөговь'},
    {city_id: 19, id: 6, name: 'Завхан'},
    {city_id: 19, id: 7, name: 'Зүүнговь'},
    {city_id: 19, id: 8, name: 'Зүүнхангай'},
    {city_id: 19, id: 9, name: 'Малчин'},
    {city_id: 19, id: 10, name: 'Наранбулаг'},
    {city_id: 19, id: 11, name: 'Өлгий'},
    {city_id: 19, id: 12, name: 'Өндөрхангай'},
    {city_id: 19, id: 13, name: 'Сагил'},
    {city_id: 19, id: 14, name: 'Тариалан'},
    {city_id: 19, id: 15, name: 'Түргэн'},
    {city_id: 19, id: 16, name: 'Тэс'},
    {city_id: 19, id: 17, name: 'Ховд'},
    {city_id: 19, id: 18, name: 'Хяргас'},
    {city_id: 19, id: 19, name: 'Цагаанхайрхан'},
    //khovd
    {city_id: 20, id: 1, name: 'Жаргалант'},
    {city_id: 20, id: 2, name: 'Алтай'},
    {city_id: 20, id: 3, name: 'Булган'},
    {city_id: 20, id: 4, name: 'Буянт'},
    {city_id: 20, id: 5, name: 'Дарви'},
    {city_id: 20, id: 6, name: 'Дөргөн'},
    {city_id: 20, id: 7, name: 'Дуут'},
    {city_id: 20, id: 8, name: 'Зэрэг'},
    {city_id: 20, id: 9, name: 'Манхан'},
    {city_id: 20, id: 10, name: 'Мөнххайрхан'},
    {city_id: 20, id: 11, name: 'Мөст'},
    {city_id: 20, id: 12, name: 'Мянгад'},
    {city_id: 20, id: 13, name: 'Үенч'},
    {city_id: 20, id: 14, name: 'Ховд'},
    {city_id: 20, id: 15, name: 'Цэцэг'},
    {city_id: 20, id: 16, name: 'Чандмань'},
    {city_id: 20, id: 17, name: 'Эрдэнэбүрэн'},
    //khovsgol
    {city_id: 21, id: 1, name: 'Мөрөн'},
    {city_id: 21, id: 2, name: 'Алаг-Эрдэнэ'},
    {city_id: 21, id: 3, name: 'Арбулаг'},
    {city_id: 21, id: 4, name: 'Баянзүрх'},
    {city_id: 21, id: 5, name: 'Бүрэнтогтох'},
    {city_id: 21, id: 6, name: 'Галт'},
    {city_id: 21, id: 7, name: 'Жаргалант'},
    {city_id: 21, id: 8, name: 'Их-Уул'},
    {city_id: 21, id: 9, name: 'Цэцэрлэг'},
    {city_id: 21, id: 10, name: 'Рашаант'},
    {city_id: 21, id: 11, name: 'Рэнчинлхүмбэ'},
    {city_id: 21, id: 12, name: 'Тариалан'},
    {city_id: 21, id: 13, name: 'Тосонцэнгэл'},
    {city_id: 21, id: 14, name: 'Төмөрбулаг'},
    {city_id: 21, id: 15, name: 'Түнэл'},
    {city_id: 21, id: 16, name: 'Улаан-Уул'},
    {city_id: 21, id: 17, name: 'Ханх'},
    {city_id: 21, id: 18, name: 'Цагаан-Уул'},
    {city_id: 21, id: 19, name: 'Цагаан-Үүр'},
    {city_id: 21, id: 20, name: 'Цагааннуур'},
    {city_id: 21, id: 21, name: 'Чандмань-Өндөр'},
    {city_id: 21, id: 22, name: 'Шинэ-Идэр'},
    {city_id: 21, id: 23, name: 'Эрдэнэбулган'},
    //khentii
    {city_id: 22, id: 1, name: 'Батноров'},
    {city_id: 22, id: 2, name: 'Батширээт'},
    {city_id: 22, id: 3, name: 'Баян-Адарга'},
    {city_id: 22, id: 4, name: 'Баян-Овоо'},
    {city_id: 22, id: 5, name: 'Баянмөнх'},
    {city_id: 22, id: 6, name: 'Баянхутаг'},
    {city_id: 22, id: 7, name: 'Биндэр'},
    {city_id: 22, id: 8, name: 'Бор-Өндөр'},
    {city_id: 22, id: 9, name: 'Галшар'},
    {city_id: 22, id: 10, name: 'Өмнөдэлгэр'},
    {city_id: 22, id: 11, name: 'Дадал'},
    {city_id: 22, id: 12, name: 'Дархан'},
    {city_id: 22, id: 13, name: 'Дэлгэрхаан'},
    {city_id: 22, id: 14, name: 'Жаргалтхаан'},
    {city_id: 22, id: 15, name: 'Мөрөн'},
    {city_id: 22, id: 16, name: 'Хэрлэн'},
    {city_id: 22, id: 17, name: 'Норовлин'},
    {city_id: 22, id: 18, name: 'Цэнхэрмандал'},
  ],
  travel_category: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 20],
  travelLanguages: [1, 2, 3, 4, 5, 6],
  travelLanguage: [
    {
      id: 1,
      name: 'Mongolian',
      code: 'mn-MN',
      short_code: 'mn',
    },
    {
      id: 2,
      name: 'English',
      code: 'en-US',
      short_code: 'en',
    },
    {
      id: 3,
      name: 'Chinese',
      code: 'cmn-CN',
      short_code: 'cmn',
    },
    {
      id: 4,
      name: 'Russian',
      code: 'ru-RU',
      short_code: 'ru',
    },
    {
      id: 5,
      name: 'Korean',
      code: 'ko-KR',
      short_code: 'ko',
    },
    {
      id: 6,
      name: 'Japanese',
      code: 'ja-JP',
      short_code: 'ja',
    },
    {
      id: 7,
      name: 'German',
      code: 'de-DE',
      short_code: 'de',
    },
    {
      id: 8,
      name: 'French',
      code: 'fr-FR',
      short_code: 'fr',
    },
    {
      id: 9,
      name: 'Spanish',
      code: 'es-ES',
      short_code: 'es',
    },
    {
      id: 10,
      name: 'Turkish',
      code: 'tr-TR',
      short_code: 'tr',
    },
    {
      id: 11,
      name: 'Indonesian',
      code: 'is-IS',
      short_code: 'is',
    },
    {
      id: 12,
      name: 'Italian',
      code: 'it-IT',
      short_code: 'it',
    },
    {
      id: 13,
      name: 'Phillipines',
      code: 'fil-PH',
      short_code: 'fil',
    },
    {
      id: 14,
      name: 'Afrikaans',
      code: 'af-ZA',
      short_code: 'af',
    },
    {
      id: 15,
      name: 'Arabic',
      code: 'ar-XA',
      short_code: 'ar',
    },
    {
      id: 16,
      name: 'Bengali Inida',
      code: 'bn-IN',
      short_code: 'bn',
    },
    {
      id: 17,
      name: 'Danish',
      code: 'da-DK',
      short_code: 'da',
    },
    {
      id: 18,
      name: 'Finnish',
      code: 'fi-FI',
      short_code: 'fi',
    },
    {
      id: 19,
      name: 'Greek',
      code: 'el-GR',
      short_code: 'el',
    },
    {
      id: 20,
      name: 'Czech',
      code: 'cs-CZ',
      short_code: 'cs',
    },
    {
      id: 21,
      name: 'Hebrew Israel',
      code: 'he-IL',
      short_code: 'he',
    },
    {
      id: 22,
      name: 'Hindi India',
      code: 'hi-IN',
      short_code: 'hi',
    },
    {
      id: 23,
      name: 'Hungarian',
      code: 'hu-HU',
      short_code: 'hu',
    },
    {
      id: 24,
      name: 'Icelandic',
      code: 'is-IS',
      short_code: 'is',
    },
    {
      id: 25,
      name: 'Vietnamese',
      code: 'vi-VN',
      short_code: 'vi',
    },
    {
      id: 26,
      name: 'Malay malaysia',
      code: 'ms-MY',
      short_code: 'ms',
    },
    {
      id: 27,
      name: 'Ukraine',
      code: 'uk-UA',
      short_code: 'uk',
    },
    {
      id: 28,
      name: 'Norwegian',
      code: 'nb-NO',
      short_code: 'nb',
    },
    {
      id: 29,
      name: 'Polish',
      code: 'pl-PL',
      short_code: 'pl',
    },
    {
      id: 30,
      name: 'Portuguese portugal',
      code: 'pt-PT',
      short_code: 'pt',
    },
    {
      id: 31,
      name: 'Romania',
      code: 'ro-RO',
      short_code: 'ro',
    },
    {
      id: 32,
      name: 'Serbian',
      code: 'sr-RS',
      short_code: 'sr',
    },
    {
      id: 33,
      name: 'Slovak ',
      code: 'sk-SK',
      short_code: 'sk',
    },
    {
      id: 34,
      name: 'Swedish',
      code: 'sv-SE',
      short_code: 'sv',
    },
    {
      id: 35,
      name: 'Latvian',
      code: 'lv-LV',
      short_code: 'lv',
    },
    {
      id: 36,
      name: 'Lithuanian ',
      code: 'lt-LT',
      short_code: 'lt',
    },
    {
      id: 37,
      name: 'Thai thailand',
      code: 'th-TH',
      short_code: 'th',
    },
    {
      id: 38,
      name: 'Kazakh',
      code: 'kk-KZ',
      short_code: 'kk',
    },
  ],
}
//#region Customer

export const CustomerAddReq = {
  ...BaseRequest,
  customer: {
    rd: '',
    name: '',
    type: '',
    address_city: '',
    address_district: '',
    address: '',
    albantushaal: '',
    utas: '',
    uid: '',
  },
}

export const CustomerListReq = {
  ...ListBase,
  filter: {
    rd: '',
    name: '',
    type: '',
    address_city: '',
    address_district: '',
    address: '',
    albantushaal: '',
    utas: '',
    uid: '',
  },
}

//#endregion Customer

//#region Invoice
export const InvoiceReq = {
  ...BaseRequest,
  invoice: {
    invoice_code: 'TEST_INVOICE',
    sender_invoice_no: '1234567',
    invoice_receiver_code: 'terminal',
    invoice_description: 'test',
    amount: 1,
  },
  callback_url: '',
}
export const InvoicCheckPaymentReq = {
  ...BaseRequest,
  invoiceID: '',
}
export const InvoiceAddReq = {
  ...BaseRequest,
  invoice: {
    org_rd: '',
    org_name: '',
    uid: '',
    email: '',
    subscription: '',
    month: '',
    discount: '',
    price: '',
    total_price: '',
    status: '',
    skey: '',
  },
}
export const InvoiceListReq = {
  ...ListBase,
  filter: {
    uid: '',
    org_rd: '',
    status: '',
    subscription: '',
  },
}
export const InvoiceChangeStatusReq = {
  ...BaseRequest,
  invoice: {
    status: '',
    id: '',
  },
}
export const gereeAddReq = {
  ...BaseRequest,
  geree: {
    org_rd: '',
    firstname: '',
    lastname: '',
    albantushaal: '',
    phone: '',
    address: '',
    invoice_id: '',
    signature: null,
    uid: '',
  },
}
export const gereeListReq = {
  ...ListBase,
  filter: {
    uid: '',
    org_rd: '',
    invoice_id: '',
    org_id: '',
  },
}

//#endregion

//#region employee
export const AuthReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  Status: '',
  FirstName: '',
  LastName: '',
  ExpiredDate: '',
  TypeID: '',
  file: '',
}

export const AuthCreateReq = {
  ...BaseRequest,
  UserName: '',
  Password: '',
}

export const ChangePassReq = {
  ...BaseRequest,
  EmployeeID: '',
  newPassword: '',
  OLdPass: '',
  IsAdmin: false,
  realOldPass: '',
}

export const EmployeeChangeInfoReq = {
  ...BaseRequest,
  FirstName: '',
  LastName: '',
  Username: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  id: '',
}

export const EmployeeAddReq = {
  ...BaseRequest,
  Username: '',
  Password: '',
  FirstName: '',
  LastName: '',
  Status: 0,
  TypeID: '',
  Noc: '',
  Photo: '',
}

export const EmployeeChangeStatusReq = {
  ...BaseRequest,
  status: 0,
  id: '',
}

export const listEmployeeReq = {
  ...ListBase,
  filter: {
    Name: '',
    SoHoID: '',
    HeHoID: '',
    HesegID: '',
    BagID: '',
    Status: '',
  },
}
//#endregion employee

//#region Privilege
export const PrivilegeGroupAddReq = {
  ...BaseRequest,
  mPriv_Group: {
    PrivGroupID: '',
    PrivGroupName: '',
    IsUserGroup: '',
  },
}

export const PrivilegeGroupMoreReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeGroupDeleteReq = {
  ...BaseRequest,
  mPriv_Group: {},
}

export const PrivilegeAddReq = {
  ...BaseRequest,
  priv: {
    privNo: '',
    privName: '',
  },
}

export const PrivilegeDeleteReq = {
  ...BaseRequest,
  mPriv: {},
}

export const PrivilegeUserListReq = {
  ...BaseRequest,
  privNo: '',
}

export const GetEmployeeByIdReq = {
  ...BaseRequest,
  EmployeeID: '',
}

export const PrivilegeMP_GroupUserAddReq = {
  ...BaseRequest,
  userKey: '',
  mPriv_Groups: [{privGroupId: null}],
}

export const AddMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
  mPrivs: [],
}

export const GetMPriv_GroupPrivReq = {
  ...BaseRequest,
  privGroupId: '',
}

export const ListMPriv_GroupPrivReq = {
  ...BaseRequest,
  MPriv_Group: {},
}
//#endregion

//#region Log
export const LogActionReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Action: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}

export const LogErrorReq = {
  ...ListBase,
  filter: {
    Controller: '',
    Error: '',
    UserID: '',
    BeginDate: '',
    EndDate: '',
  },
}
//#endregion

//#region Search
export const SearchListReq = {
  ...ListBase,
  filter: {
    uid: '',
    result_count: '',
  },
}

//#endregion

//#region Flipbook
export const FlipbookListReq = {
  ...ListBase,
  filter: {
    flipbook_type: '',
    status: '',
    uid: '',
  },
}
export const FlipbookGetReq = {
  ...BaseRequest,
  flipbook: {
    mac: '',
    id: '',
  },
}
export const FlipbookMoreReq = {
  ...ListBase,
  filter: {
    flipbookid: '',
    start_at: '',
    end_at: '',
  },
}
export const FlipbookEditNameReq = {
  ...BaseRequest,
  qr: {
    name: '',
    id: '',
  },
}
export const FlipbookEditUrlReq = {
  ...BaseRequest,
  qr: {
    qr_url: '',
    id: '',
  },
}
export const FlipbookStatListReq = {
  ...ListBase,
  filter: {
    flipbook: {
      id: '',
    },
  },
}

//#endregion

//#region Dashboard
export const DashboardNotifiWorkProjectReq = {
  ...BaseRequest,
  userid: '',
}

export const DashboardAsuudalBatalgaajaaguiReq = {
  ...BaseRequest,
  filter: {
    is_confirm: '',
  },
}

export const DashboardUserbyMounthReq = {
  ...BaseRequest,
  filter: {
    year: '',
    type: '',
  },
}

export const DashboardTopAsuudalReq = {
  ...BaseRequest,
  filter: {
    limit: 5,
  },
}

export const DashboardLastCancelledReq = {
  ...BaseRequest,
  type: '',
}

export const DashboardByUserReq = {
  ...BaseRequest,
  filter: {
    service_id: '',
    year: '',
    mounth: '',
  },
}

export const DashboardToonMedeelelReq = {
  ...BaseRequest,
  filter: {
    created_by: '',
  },
}

export const DashboardByStatusReq = {
  ...BaseRequest,
  filter: {
    customer_id: '',
  },
}

export const DashboardByOrderReq = {
  ...BaseRequest,
  filter: {
    year: '',
    month: '',
  },
}

export const DashboardByBorluulaltByDayReq = {
  ...BaseRequest,
  filter: {
    year: '',
    month: '',
    day: '',
    customer_id: '',
  },
}
//#endregion

//#region News
export const NewsListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
  },
}

export const NewsAddReq = {
  ...BaseRequest,
  news: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
  },
}

export const NewsChangeImageReq = {
  ...BaseRequest,
  news: {
    old_image_link: '',
    image_link: '',
    id: '',
  },
}

export const NewsUpdateReq = {
  ...BaseRequest,
  News: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    id: '',
  },
}

export const NewsChangeStatusReq = {
  ...BaseRequest,
  News: {
    status: 0,
    id: '',
  },
}

export const NewsContentAddReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
    body: '',
  },
}

export const NewsContentUpdateReq = {
  ...BaseRequest,
  News: {
    id: '',
    body: '',
  },
}

export const NewsContentGetReq = {
  ...BaseRequest,
  News: {
    rel_id: '',
    type: '',
  },
}
//#endregion

//#region Pages
export const PagesListReq = {
  ...ListBase,
  filter: {
    pageid: '',
    title: '',
  },
}

export const PagesAddReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
  },
}

export const PagesUpdateReq = {
  ...BaseRequest,
  page: {
    title: '',
    pageid: '',
    id: '',
  },
}

export const PagesContentAddReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
    type: '',
    body: '',
  },
}

export const PagesContentUpdateReq = {
  ...BaseRequest,
  page: {
    id: '',
    pageid: '',
    lang: '',
    body: '',
  },
}

export const PagesContentGetReq = {
  ...BaseRequest,
  page: {
    pageid: '',
    lang: '',
  },
}
//#endregion

//#region Videos
export const VideosListReq = {
  ...ListBase,
  filter: {
    title: '',
    lang: '',
    category: '',
    order: false,
  },
}

export const VideosAddReq = {
  ...BaseRequest,
  video: {
    title: '',
    lang: '',
    category: 0,
    desc: '',
    image_link: '',
    youtube_id: '',
  },
}

export const VideosUpdateReq = {
  ...BaseRequest,
  video: {
    title: '',
    desc: '',
    lang: '',
    category: '',
    youtube_id: '',
    id: '',
  },
}

export const VideosChangeStatusReq = {
  ...BaseRequest,
  video: {
    status: 0,
    id: '',
  },
}

export const VideoChangeImageReq = {
  ...BaseRequest,
  video: {
    image_link: '',
    old_image_link: '',
    id: '',
  },
}
//#endregion

//#region Banner
export const BannerListReq = {
  ...ListBase,
  filter: {
    position: '',
    tolov: '',
  },
}

export const BannerAddReq = {
  ...BaseRequest,
  banner: {
    position: '',
    image_link: '',
    tolov: '',
    button_name: '',
    button_link: '',
  },
}

export const BannerUpdateReq = {
  ...BaseRequest,
  banner: {
    position: '',
    image_link: '',
    tolov: '',
    button_name: '',
    button_link: '',
    id: '',
  },
}

export const BannerUpdateTolovReq = {
  ...BaseRequest,
  banner: {
    tolov: '',
    id: '',
  },
}
//#endregion

//#region ProductContent
export const ProductContentAddReq = {
  ...BaseRequest,
  content: {
    content: '',
    type: '',
    relation_id: '',
    id: '',
  },
}

export const ProductContentGetReq = {
  ...BaseRequest,
  content: {
    type: '',
    relation_id: '',
  },
}
//#endregion

//#region User
export const UserListReq = {
  ...ListBase,
  filter: {
    mobile: '',
    id: '',
    delguur: '',
    city_id: '',
    district_id: '',
    khoroo_id: '',
  },
}

export const UserSungahReq = {
  ...BaseRequest,
  customer: {
    id: '',
    active_at: '',
  },
}

export const UserAddReq = {
  ...BaseRequest,
  customer: {
    delguur: '',
    mobile: '',
    pin: '',
  },
}

export const UserUpdateReq = {
  ...BaseRequest,
  customer: {
    delguur: '',
    mobile: '',
    pin: '',
    id: '',
  },
}
//#endregion

//#region Product
export const ProductPriceListReq = {
  ...ListBase,
  filter: {
    product_id: '',
    created_by: '',
    city_id: '',
    district_id: '',
    khoroo_id: '',
    year: '',
    month: '',
  },
}

export const ProductListReq = {
  ...ListBase,
  filter: {
    name: '',
    barcode: '',
  },
}

export const ProductAddReq = {
  ...BaseRequest,
  product: {
    name: '',
    barcode: '',
    price: '',
  },
}

export const ProductUpdateReq = {
  ...BaseRequest,
  product: {
    name: '',
    barcode: '',
    price: '',
    id: '',
  },
}
//#endregion

//#region DTemplete
export const DTempleteTagsUpdateReq = {
  ...BaseRequest,
  desgin: {
    tags: '',
    id: '',
  },
}

export const DTempleteAddReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    template: '',
    preview: '',
    status: 0,
    category: '',
    sub_category: '',
    created_by: '',
  },
}

export const DTempleteUpdateReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    category: '',
    sub_category: '',
    template: '',
    old_preview: '',
    preview: '',
    id: '',
  },
}

export const DTempleteUpdateInfoReq = {
  ...BaseRequest,
  desgin: {
    name: '',
    category: '',
    sub_category: '',
    price: '',
    temdeglelt_odor: '',
    level: '',
    id: '',
  },
}

export const DTempleteChangeStatusReq = {
  ...BaseRequest,
  desgin: {
    status: '',
    id: '',
  },
}

export const DTempleteListReq = {
  ...ListBase,
  desgin: {
    id: '',
    skey: '',
    name: '',
    status: '',
    category: '',
    sub_category: '',
    segment: '',
    created_by: '',
  },
}

export const DTemplateMakeReq = {
  ...ListBase,
  filter: {
    tid: '',
    did: '',
    uid: '',
    type: '',
  },
}

export const DTemplateChangeOrgReq = {
  ...BaseRequest,
  desgin: {
    org_id: '',
    id: '',
  },
}
//#endregion DTemplete

//#region DUpload
export const DUploadAddReq = {
  ...BaseRequest,
  dupload: {
    is_public: '',
    created_by: '',
  },
}

export const DUploadListReq = {
  ...ListBase,
  dupload: {
    is_public: '',
    name: '',
    created_by: '',
  },
}

export const DImageUpdateInfoReq = {
  ...ListBase,
  dupload: {
    is_public: '',
    name: '',
    id: '',
  },
}
//#endregion DTemaplete

//#region DFonts
export const DFontsAddReq = {
  ...BaseRequest,
  font: {
    family: '',
    created_by: '',
  },
}

export const DFontsChangeStatusReq = {
  ...BaseRequest,
  font: {
    status: '',
    id: '',
  },
}

export const DFontsListReq = {
  ...ListBase,
  filter: {
    family: '',
    created_by: '',
    skey: '',
    status: '',
    category: '',
    sub_category: '',
  },
}
//#endregion DFonts

//#region DElement
export const DElementChangeStatusReq = {
  ...BaseRequest,
  element: {
    status: '',
    id: '',
  },
}

export const DElementChangeBBStatusReq = {
  ...BaseRequest,
  element: {
    level: '',
    id: '',
  },
}

export const DElementTagsUpdateReq = {
  ...BaseRequest,
  element: {
    tags: '',
    id: '',
  },
}

export const DElementAddReq = {
  ...BaseRequest,
  element: {
    name: '',
    template: '',
    is_public: '',
    preview: '',
    created_by: '',
  },
}

export const DElementListReq = {
  ...ListBase,
  filter: {
    skey: '',
    name: '',
    category: '',
    sub_category: '',
    created_by: '',
    is_public: '',
  },
}

export const DElementUpdateInfoReq = {
  ...ListBase,
  element: {
    name: '',
    is_public: '',
    category: '',
    sub_category: '',
    price: '',
    level: '',
    id: '',
  },
}
//#endregion DElement

//#region DCollection
export const DCollectionChangeStatusReq = {
  ...BaseRequest,
  collection: {
    status: '',
    id: '',
  },
}

export const DCollectionChangeImageReq = {
  ...BaseRequest,
  collection: {
    image: '',
    old_image: '',
    id: '',
  },
}

export const DCollectionAddReq = {
  ...BaseRequest,
  collection: {
    name: '',
    description: '',
    status: '',
    image: '',
    templates: '',
  },
}

export const DCollectionListReq = {
  ...ListBase,
  filter: {
    skey: '',
    name: '',
    status: '',
  },
}

export const DCollectionUpdateReq = {
  ...ListBase,
  collection: {
    name: '',
    description: '',
    status: '',
    image: '',
    templates: '',
    id: '',
  },
}
//#endregion DElement

//#region DTextStyles
export const DTextStylesListReq = {
  ...ListBase,
  desgin: {
    status: 0,
    tags: '',
    skey: '',
  },
}

export const DTextStylesChangeStatusReq = {
  ...ListBase,
  desgin: {
    status: 0,
    id: '',
  },
}
//#endregion

//#region Designers
export const DesignerChangePassReq = {
  ...BaseRequest,
  designerID: '',
  newPassword: '',
  OLdPass: '',
  IsAdmin: false,
  realOldPass: '',
}
export const DesignerListReq = {
  ...ListBase,
  filter: {
    name: '',
    mobile: '',
    email: '',
    is_designer: '',
    provider: '',
    id: '',
    org_id: '',
  },
}
export const DesignerAddReq = {
  ...BaseRequest,
  designer: {
    mobile: '',
    email: '',
    name: '',
    image: '',
    password: '',
    is_designer: '',
    provider: '',
  },
}
export const DesignerChangeOrgReq = {
  ...BaseRequest,
  designer: {
    org_id: '',
    id: '',
  },
}
export const DesignerUpdateReq = {
  ...BaseRequest,
  designer: {
    mobile: '',
    email: '',
    name: '',
    is_designer: '',
    provider: '',
    id: '',
  },
}
export const DesignerBanReq = {
  ...BaseRequest,
  designer: {
    id: '',
    level: '',
  },
}
export const DesignerConnectedOrgReq = {
  ...BaseRequest,
  filter: {
    org_id: '',
    org_rd: '',
  },
}
export const DesignerUpdateOrgReq = {
  ...BaseRequest,
  designer: {
    org_rd: '',
    id: '',
  },
}
//#endregion DElement

//#region Org
export const OrgListReq = {
  ...ListBase,
  filter: {
    name: '',
    umch: '',
    status: '',
    register: '',
  },
}
export const OrgAddReq = {
  ...BaseRequest,
  org: {
    name: '',
    url: '',
    logo: '',
    register: '',
    umch: '',
  },
}
export const OrgUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    url: '',
    register: '',
    umch: '',
    id: '',
  },
}
export const OrgChangelogoReq = {
  ...BaseRequest,
  org: {
    id: '',
    logo: '',
    old_logo: '',
  },
}
export const OrgChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}

export const OrgLogosListReq = {
  ...ListBase,
  filter: {
    status: '',
    logo_type: '',
    lang: '',
    position: '',
    org_id: '',
    color_type: '',
  },
}
export const OrgLogosAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    logo: '',
    position: '',
    lang: '',
    status: '',
    logo_type: '',
    color_type: '',
  },
}
export const OrgLogosUpdateReq = {
  ...BaseRequest,
  org: {
    position: '',
    lang: '',
    logo_type: '',
    color_type: '',
    id: '',
  },
}
export const OrgLogosChangelogoReq = {
  ...BaseRequest,
  org: {
    id: '',
    logo: '',
    old_logo: '',
  },
}
export const OrgLogosChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}

export const OrgColorsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
    type: '',
  },
}
export const OrgColorsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    color: '',
    color_type: '',
  },
}
export const OrgColorsUpdateReq = {
  ...BaseRequest,
  org: {
    color: '',
    color_type: '',
    id: '',
  },
}

export const OrgFontsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgFontsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    font_id: '',
  },
}
export const OrgFontsUpdateReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    font_id: '',
    id: '',
  },
}

export const OrgElementsListReq = {
  ...ListBase,
  filter: {
    org_id: '',
    category: '',
  },
}
export const OrgElementsAddReq = {
  ...BaseRequest,
  org: {
    org_id: '',
    element_id: '',
    category: '',
  },
}
export const OrgElementsUpdateReq = {
  ...BaseRequest,
  org: {
    element_id: '',
    category: '',
    id: '',
  },
}

export const OrgPptListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgPptChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}
export const OrgPptUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    version: '',
    id: '',
  },
}
export const OrgPptAddImageReq = {
  ...BaseRequest,
  org: {
    id: '',
    preview: '',
    old_preview: '',
  },
}

export const OrgPdfListReq = {
  ...ListBase,
  filter: {
    org_id: '',
  },
}
export const OrgPdfChangeStatusReq = {
  ...BaseRequest,
  org: {
    status: '',
    id: '',
  },
}
export const OrgPdfUpdateReq = {
  ...BaseRequest,
  org: {
    name: '',
    version: '',
    id: '',
  },
}
export const OrgPdfAddImageReq = {
  ...BaseRequest,
  org: {
    id: '',
    preview: '',
    old_preview: '',
  },
}
//#endregion Org

//#region DWallet_History
export const DWallet_HistoryListReq = {
  ...ListBase,
  filter: {
    wallet_id: '',
    month: '',
    year: '',
    day: '',
  },
}

export const DWallet_HistoryAddReq = {
  ...BaseRequest,
  wallet: {
    wallet_id: '',
    price: 0,
    walletPrev: 0,
  },
}
//#endregion DElement

//#region Subscription
export const SubscriptionAddReq = {
  ...BaseRequest,
  order: {
    uid: '',
    bagts: '',
    sungasan_sar: '',
    bagts_hugatsaa: '',
    payment: '',
    amount: '',
    status: '',
    org_rd: '',
    school_id: null,
    ebarimt_email: '',
    skey: '',
    ashiglah_too: '',
  },
}
export const SubscriptionConnectOrgReq = {
  ...BaseRequest,
  subscription: {
    org_rd: '',
    id: ''
  },
}

export const SubscriptionListReq = {
  ...ListBase,
  filter: {
    uid: '',
    bagts: '',
    status: '',
    payment: '',
    year: '',
    month: '',
    day: '',
    org_rd: '',
    ebarimt_email: '',
    ebarimt_ilgeesen: '',
  },
}
//#endregion Subscription

//#region School
export const SchoolListReq = {
  ...ListBase,
  filter: {
    name: '',
    umch: '',
    angilal: '',
    register: '',
    address_city: '',
    address_district: '',
  },
}
//#endregion School

//#region LetterBanner
export const LetterBannerListReq = {
  ...ListBase,
  filter: {
    pid: '',
    relation_type: '',
    uid: '',
    payment: '',
    year: '',
    month: '',
    day: '',
  },
}
export const LetterBannerUpdateReq = {
  ...BaseRequest,
  order: {
    id: '',
    tolov: '',
    ug: '',
  },
}
export const DYOrderAddReq = {
  ...BaseRequest,
  order: {
    relation_type: "",
    uid: "",
    pid: "",
    ug: "",
    amount: "",
    payment: "",
  },
};
//#endregion

//#region Feedback
export const FeedbackListReq = {
  ...ListBase,
  filter: {
    main_type: '',
    rel_type: '',
    rel_id: '',
    desc: '',
    attach: '',
    uid: '',
    uname: '',
    uphone: '',
  },
}

//#endregion

//#region QR
export const QRAddReq = {
  ...BaseRequest,
  qr: {
    name: '',
    qr_type: '',
    qr_url: '',
    uid: '',
  },
}

export const QRTravelUpdateReq = {
  ...BaseRequest,
  qr: {
    map: '',
    city: '',
    district: '',
    category: '',
    long: '',
    lati: '',
    id: '',
  },
}

export const QRTravelRouteAddReq = {
  ...BaseRequest,
  route: {
    city: '',
    district: '',
    name: '',
    color: '',
    cover: '',
    uid: '',
  },
}
export const QRTravelRouteUpdateReq = {
  ...BaseRequest,
  route: {
    city: '',
    district: '',
    name: '',
    color: '',
    id: '',
  },
}
export const QRTravelRouteChangemapReq = {
  ...BaseRequest,
  route: {
    map: '',
    old_map: '',
    id: '',
  },
}
export const QRTravelRouteChangecoverReq = {
  ...BaseRequest,
  route: {
    cover: '',
    old_cover: '',
    id: '',
  },
}

export const QRCodeAddReq = {
  ...BaseRequest,
  qr: {
    qr_code: '',
    id: '',
  },
}
export const QRListReq = {
  ...ListBase,
  filter: {
    qr_type: '',
    status: '',
    uid: '',
  },
}
export const QRTravelRouteListReq = {
  ...ListBase,
  filter: {
    city: '',
    district: '',
    status: '',
    uid: '',
  },
}

export const QRTravelRouteChangestatusReq = {
  ...BaseRequest,
  route: {
    status: '',
    id: '',
  },
}
export const QRTravelRoutePointaddReq = {
  ...BaseRequest,
  route: {
    points: [],
    id: '',
  },
}
export const QRTravelRoutePointUpdateReq = {
  ...BaseRequest,
  point: {
    daraalal: '',
    name: '',
    distance: '',
    id: '',
    tid: '',
    rid: '',
  },
}
export const editQRTravelLangReq = {
  ...BaseRequest,
  qr: {
    id: '',
    url: '',
  },
}

export const addQRTravelLangReq = {
  ...BaseRequest,
  qr: {
    id: '',
    langs: [],
  },
}

export const QRGetReq = {
  ...BaseRequest,
  qr: {
    mac: '',
    id: '',
  },
}
export const QRMoreReq = {
  ...ListBase,
  filter: {
    qrid: '',
    start_at: '',
    end_at: '',
  },
}
export const QREditNameReq = {
  ...BaseRequest,
  qr: {
    name: '',
    id: '',
  },
}
export const QREditUrlReq = {
  ...BaseRequest,
  qr: {
    qr_url: '',
    id: '',
  },
}
export const QRStatListReq = {
  ...ListBase,
  filter: {
    qr: {
      id: '',
    },
  },
}
export const QRTravelRoutePointListReq = {
  ...ListBase,
  filter: {
    rid: '',
  },
}
export const QRTravelListReq = {
  ...ListBase,
  filter: {
    qr_type: '',
    status: '',
    uid: '',
    category: '',
    name: '',
  },
}
export const QRTravelImagesListReq = {
  ...ListBase,
  filter: {
    tid: '',
  },
}

export const QRTravelImagesAddReq = {
  ...BaseRequest,
  travel: {
    tid: '',
    image: '',
  },
}

export const QRTravelTextAddReq = {
  ...BaseRequest,
  text: {
    tid: '',
    text: '',
    lang: '',
  },
}
export const QRTravelTextUpdateAudioReq = {
  ...BaseRequest,
  text: {
    text: '',
    id: '',
    tid: '',
    voiceID: '',
    lang: '',
  },
}
export const QRTravelTextGetItemReq = {
  ...BaseRequest,
  text: {
    tid: '',
    lang: '',
  },
}
export const QRTravelTextUpdateReq = {
  ...BaseRequest,
  text: {
    id: '',
    text: '',
  },
}

export const QRTravelDeletemp3Req = {
  ...BaseRequest,
  qr: {
    id: '',
  },
}
export const QRTravelText2AudioReq = {
  ...BaseRequest,
  travel: {
    id: '',
    lang: '',
    text: '',
    old_audio_url: '',
  },
}
//#endregion QR

//#region Event
export const EventAddReq = {
  ...BaseRequest,
  event: {
    name: '',
    desc: '',
    preview: '',
    uid: '',
  },
}
export const EventRegistrationReq = {
  ...BaseRequest,
  event_user: {
    id: '',
    status: '',
  },
}
export const EventListReq = {
  ...ListBase,
  filter: {
    uid: '',
  },
}
export const EventUserAddReq = {
  ...BaseRequest,
  event_id: '',
  event_users: [],
}
export const EventUserListReq = {
  ...ListBase,
  filter: {
    event_id: '',
    send_email: '',
    status: '',
  },
}
export const EventIrtsReq = {
  ...ListBase,
  filter: {
    event_id: '',
  },
}
export const EventUserUpdateReq = {
  ...BaseRequest,
  event_user: {
    name: '',
    email: '',
    type_name: '',
    type_color: '',
    id: '',
  },
}
export const EventSentMailReq = {
  ...BaseRequest,
  email: {
    from: '',
    replyto: '',
    to: '',
    subject: '',
    body: '',
    invitation: '',
    agenda: '',
    userid: '',
  },
}
export const EventEmailStatusUpdateReq = {
  ...BaseRequest,
  event_user: {
    id: '',
    send_email: '',
  },
}
//#endregion Event
