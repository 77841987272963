import React, {FC, useState} from 'react'
import {isNullOrEmpty} from '../../../services/Utils'
import {toast} from 'react-toastify'
import {DYOrderAddReq, magicnum} from '../../../services/Request'
import { DYOrderAdd } from '../../../services/main'
interface IProps {
  uid: any,
  callList:any
}
const NewBanner: FC<IProps> = ({uid, callList}) => {
  const [text, setText] = useState('')
  const [textModal, setTextModal] = useState('')
  const [bannerid, setBannerId] = useState('')
  const [loading, setLoading] = useState(false)
  const width = window.innerWidth

  const orderAddDynamic = () => {
    setLoading(true);
    var req = DYOrderAddReq;
    req.order.pid = textModal;
    req.order.uid = uid;
    req.order.amount = '6900';
    req.order.ug = text;
    req.order.payment = "admin";
    req.order.relation_type = "banner";
    DYOrderAdd(req, onOrderDynamicSuccess, onFailed, "POST");
  };

  const onOrderDynamicSuccess = (response:any) => {
    setLoading(false);
    callList()
    return toast.success("Амжилттай")
  };

  const onFailed = (response:any) => {
    setLoading(false);
    return toast.error("Амжилтгүй")
  };
  
  return (
    <div style={{width: width > 480 ? "650px" : "", minWidth: '320px', maxHeight: '90vh'}}>
      <h1 className='mb-8'>Үсгэн баннерын жагсаалт</h1>
      {!isNullOrEmpty(textModal) ? (
        <div className='flex items-center gap-3'>
          <div
            onClick={() => setTextModal('')}
            className='btn btn-sm btn-light-primary'
            style={{width: 'fit-content'}}
          >
            <i className='fa-solid fa-angle-left'></i>
            <span>Буцах</span>
          </div>
          <span style={{fontSize: '14px', fontWeight: '500'}}>Banner Id: {textModal}</span>
        </div>
      ) : (
        <div style={{borderBottom: '2px solid #eee'}} className='flex items-center gap-4 pb-2'>
          <span style={{fontSize: '16px', fontWeight: '600', color: 'gray'}}>Хайх:</span>
          <input
            className='form-control form-control-solid'
            style={{minWidth: '250px'}}
            onChange={(e) => setBannerId(e.target.value)}
            value={bannerid}
            type='text'
            name=''
            id=''
          />
        </div>
      )}

      <div className='flex flex-col gap-1 mt-5'>
        {isNullOrEmpty(textModal) ? (
          magicnum.letters
            .filter((i) => i.status === true && i.url.includes(bannerid))
            .map((el: any) => {
              return (
                <div key={el.id} className='bannerlist-tab'>
                  <div className='flex items-center gap-3'>
                    <span>{el.url}</span>
                    <img style={{height: '40px'}} src={'/letters/' + el.url + '.jpg'} alt='' />
                  </div>

                  <div
                    onClick={() => setTextModal(el.url)}
                    className='add-btn'
                    style={{height: '28px'}}
                  >
                    <span style={{fontSize: '12px'}}>add text</span>
                  </div>
                </div>
              )
            })
        ) : (
          <div className='flex flex-col gap-2'>
            <div className='flex flex-col gap-5 items-center'>
              <div className='flex gap-4'>
                <img style={{height: '80px'}} src={'/letters/' + textModal + '.jpg'} alt='' />
                <textarea
                  maxLength={40}
                  className='form-control form-control-solid'
                  style={{minWidth: '220px', height: '80px', padding: '4px'}}
                  onChange={(e) => setText(e.target.value)}
                  value={text}
                  name=''
                  id=''
                ></textarea>
              </div>

              <div className='btn btn-primary' onClick={orderAddDynamic}>
                Хадгалах
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export {NewBanner}
